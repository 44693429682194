
import AppMixin from '@/mixins/app.mixin';
import {mixins, Options} from 'vue-property-decorator';
import ROUTES from '@/router/routes';

@Options({
  name: 'ActivationPage'
})
export default class ActivationPage extends mixins(AppMixin) {
  created() {
    this.$api.user.activate({activation_code: this.$route.params.code as string})
      .then(() => {
        this.$router.push({name: ROUTES.LOGIN})
      })
  }
}
