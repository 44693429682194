import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toHandlers as _toHandlers, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7350e730"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "input-group" }
const _hoisted_3 = {
  key: 0,
  class: "input-group-text"
}
const _hoisted_4 = ["id", "placeholder", "disabled", "value"]
const _hoisted_5 = {
  key: 1,
  class: "input-group-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_field_messages = _resolveComponent("field-messages")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createBlock(_component_Field, {
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.value) = $event)),
    name: _ctx.name || _ctx.uuid,
    label: _ctx.getFieldLabel(),
    rules: _ctx.validationRules
  }, {
    default: _withCtx(({errors, field}) => [
      _createElementVNode("fieldset", {
        class: _normalizeClass(["form-field", _ctx.getFormFieldClassNames(errors)])
      }, [
        (_ctx.label)
          ? (_openBlock(), _createElementBlock("label", {
              key: 0,
              class: "form-label",
              for: _ctx.uuid
            }, _toDisplayString(_ctx.getLabel()), 9 /* TEXT, PROPS */, _hoisted_1))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.$slots.prefix)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                _renderSlot(_ctx.$slots, "prefix", {}, undefined, true)
              ]))
            : _createCommentVNode("v-if", true),
          _createVNode(_component_DatePicker, {
            modelValue: _ctx.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value) = $event)),
            class: "w-100",
            masks: _ctx.masks
          }, {
            default: _withCtx(({inputValue, inputEvents}) => [
              _createElementVNode("input", _mergeProps(field, {
                id: _ctx.uuid,
                placeholder: _ctx.placeholder,
                disabled: _ctx.disabled,
                readonly: true,
                autocomplete: false,
                value: inputValue,
                class: ['form-control', ..._ctx.classNames, ...(errors.length ? ['is-invalid'] : [])].join(' '),
                type: "text",
                onFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onFocus($event))),
                onFocusout: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onFocusOut($event)))
              }, _toHandlers(inputEvents)), null, 16 /* FULL_PROPS */, _hoisted_4)
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "masks"]),
          _renderSlot(_ctx.$slots, "buttonSuffix", {}, undefined, true),
          (_ctx.$slots.suffix)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                _renderSlot(_ctx.$slots, "suffix", {}, undefined, true)
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _createVNode(_component_field_messages, {
          "info-message": _ctx.infoMessage,
          errors: errors
        }, null, 8 /* PROPS */, ["info-message", "errors"])
      ], 2 /* CLASS */)
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["modelValue", "name", "label", "rules"]))
}