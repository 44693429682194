import {SelectOption} from '@/ui/components/Form/Select/select.types';
import {UserSex} from '@/enums/user.enum';
import i18n from '@/i18n';

export const USER_SEX_SELECT_OPTIONS: Array<SelectOption> = Object.values(UserSex)
  .filter(item => typeof item === 'number')
  .map(item => ({
    label: i18n.global.t(`user.sex_${item}`),
    value: item
  }))
