import {AbstractApi} from '@/api/modules/abstract.api';
import {AxiosInstance} from 'axios';
import BaseResponse from '@/api/response/base.response';
import {BaseRequest} from '@/api/request/base.request';
import {useNotifications} from '@/plugins/notifications';
import {showFallbackMessage} from '@/utils/api.util';
import {parseOrderOptionValue} from '@/utils/form.util';

export class BaseApi<T extends object = any> extends AbstractApi {
  constructor(axiosInstance: AxiosInstance, resource: string) {
    super(axiosInstance, resource);
  }

  show(id: number | string): Promise<BaseResponse<T>> {
    return this.axios.get(`${this.resource}/${id}`);
  }

  list(params: Partial<BaseRequest & T & Record<string, any>> = {}): Promise<BaseResponse<T[]>> | Promise<any> {
    if (params.order) {
      params = {
        ...params,
        ...parseOrderOptionValue(params.order)
      };
      delete params.order;
    }

    return this.axios.get(this.resource, {params});
  }

  create(data: Partial<T & Record<string, any>>): Promise<BaseResponse<T>> {
    return this.axios.post(this.resource, data)
      .then(r => showFallbackMessage(r as any, 'Sikeres létrehozás!'));
  }

  update(id: number, data: Partial<T & Record<string, any>>): Promise<BaseResponse<T>> {
    return this.axios.put(`${this.resource}/${id}`, data)
      .then(r => showFallbackMessage(r as any, 'Sikeres módosítás!'));
  }

  delete(id: number | string): Promise<BaseResponse> {
    return this.axios.delete(`${this.resource}/${id}`)
      .then(r => showFallbackMessage(r as any, 'Sikeres törlés!'));
  }
}
