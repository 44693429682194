import {createStore} from 'vuex';
import {AppActions, AppGetters, AppMutations, AppState, AppStore} from '@/store/types';
import {UserRole} from '@/enums/user.enum';
import {UserModel} from '@/models/user.model';
import {useApi} from '@/api';
import {DoctorModel} from '@/models/doctor.model';
import {InterventionModel} from '@/models/intervention.model';
import {SpecialistClinicModel} from '@/models/specialist-clinic.model';
import {SelectOption} from '@/ui/components/Form/Select/select.types';
import {NationalityModel} from '@/models/nationality.model';
import {MyBookedDateModel} from '@/models/my-booked-date.model';
import {OrderByDirection} from '@/api/request/base.request';

const USER_STORAGE_KEY = 'endocare_user';

const store: AppStore = createStore<AppState>({
  state: {
    user: null,
    selections: {
      specialistClinic: null,
      doctor: null,
      intervention: null,
      time: null
    },
    nationalities: [],
    dayOptions: [],
    lastRegisteredEmail: null,
    myBookedDates: [],
  },
  getters: {
    userLoggedIn: () => {
      return !!localStorage.getItem(USER_STORAGE_KEY);
    },
    userInRole: state => (roles: UserRole | UserRole[]): boolean => {
      const _roles = Array.isArray(roles) ? roles : [roles];

      if (!state.user) {
        return false;
      }

      return _roles.includes(state.user.role);
    },
    [AppGetters.NATIONALITY_SELECT_OPTIONS]: state => state.nationalities.map(item => ({
      label: item.nationality_name,
      value: item.nationality_id,
      item
    })) as Array<SelectOption<NationalityModel>>
  },
  actions: {
    [AppActions.SET_LOGGED_USER]: (injectee, payload) => injectee.commit(AppMutations.SET_LOGGED_USER, payload),
    [AppActions.INVALIDATE_LOGGED_USER]: injectee => injectee.dispatch(AppActions.SET_LOGGED_USER, null),
    [AppActions.REFRESH_LOGGED_USER]: injectee => {
      let userId: any = localStorage.getItem(USER_STORAGE_KEY);
      userId = userId ? Number(userId) : null;

      if (userId) {
        return useApi().me.getMe()
          .then(r => {
            injectee.dispatch(AppActions.SET_LOGGED_USER, r.data);
            return Promise.resolve(r.data);
          })
      }

      return Promise.resolve(null);
    },
    [AppActions.SET_DOCTOR]: (injectee, payload: DoctorModel) => {
      injectee.commit(AppMutations.SET_DOCTOR, payload);
    },
    [AppActions.SET_INTERVENTION]: (injectee, payload: InterventionModel) => {
      injectee.commit(AppMutations.SET_INTERVENTION, payload);
    },
    [AppActions.SET_SPECIALIST_CLINIC]: (injectee, payload: SpecialistClinicModel) => {
      injectee.commit(AppMutations.SET_SPECIALIST_CLINIC, payload);
    },
    [AppActions.SET_TIME]: (injectee, payload: AppState['selections']['time']) => {
      injectee.commit(AppMutations.SET_TIME, payload);
    },
    [AppActions.CLEAR_SELECTIONS]: injectee => injectee.commit(AppMutations.CLEAR_SELECTIONS),
    [AppActions.GET_NATIONALITIES]: async (injectee) => {
      const api = useApi();
      const items = (await api.dokirex.getNationalities())?.data ?? [];
      injectee.commit(AppMutations.SET_NATIONALITIES, items)
      return items;
    },
    [AppActions.GET_DAYS]: async (injectee) => {
      const api = useApi();
      const options = await api.limitation.getDays();
      injectee.commit(AppMutations.SET_DAYS, options);
      return options;
    },
    [AppActions.SET_LAST_REGISTERED_EMAIL]: (injectee, payload: string) => injectee.commit(AppMutations.SET_LAST_REGISTERED_EMAIL, payload),
    [AppActions.FETCH_MY_BOOKED_DATES]: async injectee => {
      const response = await useApi().dokirex.getMyBookedDates({order_by: 'from', order_type: OrderByDirection.asc});
      injectee.commit(AppMutations.SET_MY_BOOKED_DATES, response.data);
      return response.data;
    }
  },
  mutations: {
    [AppMutations.SET_LOGGED_USER]: (state, payload: UserModel) => {
      const userId = payload?.id ?? null;
      state.user = payload;

      if (userId) {
        localStorage.setItem(USER_STORAGE_KEY, userId?.toString())
      } else {
        localStorage.removeItem(USER_STORAGE_KEY);
      }
    },
    [AppMutations.SET_DOCTOR]: (state, payload: DoctorModel) => {
      state.selections.doctor = payload ?? null;
    },
    [AppMutations.SET_INTERVENTION]: (state, payload: InterventionModel) => {
      state.selections.intervention = payload ?? null;
    },
    [AppMutations.SET_SPECIALIST_CLINIC]: (state, payload: SpecialistClinicModel) => {
      state.selections.specialistClinic = payload ?? null;
    },
    [AppMutations.SET_TIME]: (state, payload: AppState['selections']['time']) => {
      state.selections.time = payload ?? null;
    },
    [AppMutations.CLEAR_SELECTIONS]: state => state.selections.time = null,
    [AppMutations.SET_NATIONALITIES]: (state, payload: NationalityModel[]) => state.nationalities = payload,
    [AppMutations.SET_DAYS]: (state, payload: Array<SelectOption>) => state.dayOptions = payload,
    [AppMutations.SET_LAST_REGISTERED_EMAIL]: (state, payload: string) => state.lastRegisteredEmail = payload,
    [AppMutations.SET_MY_BOOKED_DATES]: (state, payload: Array<MyBookedDateModel>) => state.myBookedDates = payload,
  }
});

export function useStore() {
  return store;
}

export default store;
