import i18n from '@/i18n'

export function updateSiteTitle(title?: string): void {
  let t = i18n.global.t
  let siteTitle = t('site.title')

  if (title) {
    siteTitle = title + ' - ' + siteTitle
  }

  document.title = siteTitle
}

export function generateUUID(): string {
  return Math.round(
    new Date().getTime() * Math.random()
  ).toString();
}

export function blobToJson(blob: Blob): Promise<any> {
  return new Promise<Object>(resolve => {
    const reader = new FileReader();
    reader.onload = function () {
      resolve(JSON.parse(this.result as string))
    };
    reader.readAsText(blob);
  });
}

declare const CUSTOMER_SERVICE_MAIL_ADDRESS: string;
declare const CUSTOMER_SERVICE_MAIL_SUBJECT: string;

export function getEnvVariables(name: string) {
  return {
    CUSTOMER_SERVICE_MAIL_ADDRESS: CUSTOMER_SERVICE_MAIL_ADDRESS,
    CUSTOMER_SERVICE_MAIL_SUBJECT: CUSTOMER_SERVICE_MAIL_SUBJECT
  }[name]
}

export function getCustomerServiceData(): {address: string, subject: string, href?: string} {
  const data = {
    address: getEnvVariables('CUSTOMER_SERVICE_MAIL_ADDRESS'),
    subject: getEnvVariables('CUSTOMER_SERVICE_MAIL_SUBJECT')
  }

  return {
    ...data,
    href: `mailto:${data.address}?subject=${data.subject}`
  }
}

const COOKIE_STORAGE_KEY = 'cookies'

export function acceptCookies(): void {
  localStorage.setItem(COOKIE_STORAGE_KEY, JSON.stringify({status: 'accepted'}))
}

export function couldShowCookie(): boolean {
  try {
    return JSON.parse(localStorage.getItem(COOKIE_STORAGE_KEY))?.status !== 'accepted'
  } catch (e) {
    return true;
  }
}

export function isMobile(): boolean {
  return window.innerWidth <= 1280;
}

export function priceInformationIsClose() {
  return localStorage.getItem('priceInformation') === 'close';
}

export function setPriceInformationState(state: 'open' | 'close') {
  localStorage.setItem('priceInformation', state);
}
