
import AppMixin from '@/mixins/app.mixin';
import {mixins, Options} from 'vue-property-decorator';
import CenterBoxWrapper from '@/ui/components/CenterBoxWrapper.vue';
import Button from '@/ui/components/Button.vue';
import ROUTES from '@/router/routes';
import {AppActions} from '@/store/types';

@Options({
  name: 'RegistrationResultPage',
  components: {Button, CenterBoxWrapper}
})
export default class RegistrationResultPage extends mixins(AppMixin) {
  routes = ROUTES;
  email: string = null;
  loading = false;

  created() {
    this.email = this.$store.state.lastRegisteredEmail;
    if (!this.email) {
      this.$router.push({name: this.routes.LOGIN});
    }
  }

  beforeUnmount() {
    this.$store.dispatch(AppActions.SET_LAST_REGISTERED_EMAIL, null)
  }

  resendEmail(): void {
    this.loading = true;
    this.$api.user.activationResend(this.email)
      .finally(() => this.loading = false)
  }
}
