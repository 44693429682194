
import AppMixin from "@/mixins/app.mixin";
import {mixins, Options, Ref} from "vue-property-decorator";
import {DoctorListItemModel} from "@/models/doctor-list-item.model";
import ROUTES from "@/router/routes";
import DividedLayout from "@/ui/layouts/DividedLayout.vue";
import CollapseContainer from "@/ui/components/CollapseContainer.vue";
import CollapseList from "@/ui/components/CollapseList.vue";
import {ListColumn} from "@/ui/components/List/list.types";
import {InterventionBlockModel} from "@/models/intervention-block.model";
import List from "@/ui/components/List/List.vue";
import Button from "@/ui/components/Button.vue";
import InterventionBlockModal from "@/ui/modals/InterventionBlockModal.vue";
import ConfirmModal from "@/ui/modals/ConfirmModal.vue";
import CustomIcon from "@/ui/components/CustomIcon.vue";
import TextInput from "@/ui/components/Form/TextInput.vue";

@Options({
  name: 'LimitBlockInnerPage',
  components: {TextInput, CustomIcon, Button, List, CollapseList, CollapseContainer, DividedLayout}
})
export default class LimitBlockInnerPage extends mixins(AppMixin) {
  @Ref('list')
  readonly list!: InstanceType<typeof List>;

  doctorListItem: DoctorListItemModel = null;
  loading = false;
  columns: ListColumn<InterventionBlockModel>[] = [
    {field: 'blocker', title: 'intervention_block.field_blocker'},
    {field: 'blocked', title: 'intervention_block.field_blocked'},
    {field: 'created_at', title: 'common.field_created_at'}
  ];
  filters: Record<string, any> = {};
  filterParams: any = {};
  routes = ROUTES;

  created() {
    this.getDoctor();
  }

  onFilterFormSubmit(): void {
    this.filterParams = {
      ...this.filters
    };
  }

  initFilters(): void {
    this.filters = {search: null};
  }

  resetFilters(): void {
    this.initFilters();
    this.onFilterFormSubmit();
  }

  openInterventionBlockModal(item: InterventionBlockModel = null): void {
    this.$modals.show(InterventionBlockModal, {
      interventionBlock: item,
      doctorListItem: this.doctorListItem,
      onSubmit: () => this.list.refreshItems()
    })
  }

  removeItem(item: InterventionBlockModel): void {
    this.$modals.show(ConfirmModal, {
      message: 'Biztosan törli a kivételt?',
      onSubmit: () => {
        this.$api.limitation.removeInterventionBlock(item.id)
          .then(() => this.list.refreshItems());
      }
    });
  }

  async getDoctor(): Promise<void> {
    try {
      this.loading = true;

      const doctorId = Number(this.$route.params.doctorId);

      if (typeof doctorId !== "number" || isNaN(doctorId)) {
        this.$notifications.showError('Hibás elérési útvonal!');
        throw null;
      }

      const doctors = (await this.$api.dokirex.getAllDoctor())?.data;
      this.doctorListItem = doctors.find(doctor => doctor.doctor_id === doctorId);

      if (!this.doctorListItem) {
        this.$notifications.showError('A keresett doktor nem található!');
        throw null;
      }
    } catch (e) {
      this.navigateToLimitations();
    } finally {
      this.loading = false;
    }
  }

  toInterventionBlock(item: InterventionBlockModel) {
    return item;
  }

  private navigateToLimitations() {
    this.$router.push({name: ROUTES.LIMITATIONS});
  }
}
