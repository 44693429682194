import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column align-items-center justify-content-center h-100" }
const _hoisted_2 = { class: "d-flex gap-2 mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.$t('page.no_permission')), 1 /* TEXT */),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t('auth.label_no_permissions')), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "btn btn-primary",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
      }, _toDisplayString(_ctx.$t('common.btn_back')), 1 /* TEXT */),
      _createElementVNode("button", {
        class: "btn btn-secondary",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.loginAsAnother && _ctx.loginAsAnother(...args)))
      }, _toDisplayString(_ctx.$t('auth.btn_login_as_another')), 1 /* TEXT */)
    ])
  ]))
}