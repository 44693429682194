export * from './user-role-select-options.constant'
export * from './user-sex-select-options.constant'

export const ORDER_RULE_CAN_NOT_BOOKED_LABEL = 'Nem foglalható';
export const ORDER_RULE_CAN_NOT_BOOKED_VALUE = 'canNotBooked';
export const ALL_DAYS_SELECT_VALUE = 'allDays';

export const PRIVACY_STATEMENT_DOC_PATH = '/docs/privacy-statement.pdf';

export const ANALYTICS_CODE = `
<script async src="https://www.googletagmanager.com/gtag/js?id=UA-177456689-2"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());  gtag('config', 'UA-177456689-2');
</script>
`;
