import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-block" }
const _hoisted_2 = { class: "mb-0" }
const _hoisted_3 = { class: "mt-5" }
const _hoisted_4 = { class: "mt-5" }
const _hoisted_5 = { class: "d-block" }
const _hoisted_6 = { class: "mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_Form = _resolveComponent("Form")!
  const _component_Button = _resolveComponent("Button")!
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createBlock(_component_modal, {
    ref: "modal",
    loading: _ctx.loading,
    "on-close": _ctx.onClose
  }, {
    title: _withCtx(() => [
      _createTextVNode("Párhuzamos kivétel " + _toDisplayString(_ctx.interventionBlock ? 'módosítása' : 'hozzáadása'), 1 /* TEXT */)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        color: "secondary",
        size: "lg",
        outlined: "",
        loading: _ctx.loading,
        "data-bs-dismiss": "modal"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('common.btn_close')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["loading"]),
      _createVNode(_component_Button, {
        color: "maroon-flush",
        size: "lg",
        onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.onFormSubmit()), ["prevent"]))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('common.btn_save')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Form, {
        ref: "vForm",
        onFormSubmit: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onFormSubmit()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_select_input, {
            modelValue: _ctx.formData.blocker_intervention_id,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.blocker_intervention_id) = $event)),
            options: _ctx.blockerInterventionSelectOptions,
            label: "blokkoló szolgáltatás",
            "validation-rules": "required",
            name: "blocker_intervention_id",
            inline: ""
          }, {
            option: _withCtx(({option}) => [
              _createElementVNode("div", null, [
                _createElementVNode("strong", _hoisted_1, _toDisplayString(_ctx.toInterventionSelectOptions(option).item.specialist_clinic_name), 1 /* TEXT */),
                _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.toInterventionSelectOptions(option).item.intervention_name), 1 /* TEXT */)
              ])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue", "options"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_select_input, {
              modelValue: _ctx.blockedDoctorId,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.blockedDoctorId) = $event)),
              options: _ctx.blockedDoctorSelectOptions,
              loading: _ctx.loading,
              "validation-rules": "required",
              label: "blokkolt szolgáltatás orvosa",
              inline: ""
            }, null, 8 /* PROPS */, ["modelValue", "options", "loading"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_select_input, {
              modelValue: _ctx.formData.blocked_intervention_id,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.blocked_intervention_id) = $event)),
              options: _ctx.blockedInterventionSelectOptions,
              label: "blokkolt szolgáltatás",
              "validation-rules": "required",
              name: "blocked_intervention_id",
              inline: "",
              "no-options-text": !_ctx.blockedDoctorId ? 'Kérem, válasszon ki egy orvost!' : 'A lista üres!'
            }, {
              option: _withCtx(({option}) => [
                _createElementVNode("div", null, [
                  _createElementVNode("strong", _hoisted_5, _toDisplayString(_ctx.toInterventionSelectOptions(option).item.specialist_clinic_name), 1 /* TEXT */),
                  _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.toInterventionSelectOptions(option).item.intervention_name), 1 /* TEXT */)
                ])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue", "options", "no-options-text"])
          ])
        ]),
        _: 1 /* STABLE */
      }, 512 /* NEED_PATCH */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["loading", "on-close"]))
}