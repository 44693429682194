
import {Emit, mixins, Options, Prop} from 'vue-property-decorator';
import {InputMixin} from '@/mixins/input.mixin';
import FieldMessages from '@/ui/components/Form/FieldMessages.vue';

@Options({
  name: 'Radio',
  components: {FieldMessages}
})
export default class Radio extends mixins(InputMixin) {
  @Prop({
    type: [String, Number, Boolean],
    required: true
  })
  readonly radioValue!: string | number | boolean;

  @Emit('change')
  emitChange(value: boolean): void {}
}
