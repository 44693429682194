export enum OrderByDirection {
  asc = 'asc',
  desc = 'desc'
}

export type OrderByDirections = keyof typeof OrderByDirection;

export interface BaseRequest {
  page?: number
  per_page?: number
  search?: string
  order?: string;
  orderBy?: string;
  orderDirection?: OrderByDirections;
}
