
import AppMixin from '@/mixins/app.mixin';
import {mixins, Options, Prop} from 'vue-property-decorator';

@Options({
  name: 'FieldMessages'
})
export default class FieldMessages extends mixins(AppMixin) {
  @Prop({type: String})
  infoMessage!: string;

  @Prop({type: [Array, Object], default: () => []})
  errors!: string[];
}
