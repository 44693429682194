export const ROUTES = {
  NOT_FOUND: 'not_found',
  NO_PERMISSION: 'no_permission',
  INDEX: 'main',
  DASHBOARD: 'dashboard',
  LOGIN: 'login',
  REGISTER: 'sign-up',
  REGISTER_RESULT: 'sign-up-result',
  USERS: 'users',
  PASSWORD_RESET: 'password-reset',
  PASSWORD_RESET_REQUEST: 'password-reset-request',
  MY_TIMES: 'my_times',
  PROFILE: 'profile',
  DOCTORS: 'doctors',
  ACTIVATION: 'activation',
  LIMITATIONS: 'limitations',
  LIMITATIONS_BLOCK_INNER: 'blocks'
}

export default ROUTES
