import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "calendar__week-day" }
const _hoisted_2 = { class: "calendar-day-header" }
const _hoisted_3 = { class: "calendar-day-title" }
const _hoisted_4 = { class: "calendar-day-subtitle" }
const _hoisted_5 = { class: "calendar-time-list" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.days, (day) => {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", _hoisted_3, _toDisplayString(day.title), 1 /* TEXT */),
        _createElementVNode("p", _hoisted_4, _toDisplayString(day.subtitle), 1 /* TEXT */)
      ]),
      _createElementVNode("ul", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(day.times, (time) => {
          return (_openBlock(), _createElementBlock("li", {
            class: _normalizeClass(["calendar-time-list-item", {selected: _ctx.isSelectedTime(day, time)}]),
            onClick: ($event: any) => (_ctx.selectTime(day, time))
          }, _toDisplayString(time.from), 11 /* TEXT, CLASS, PROPS */, _hoisted_6))
        }), 256 /* UNKEYED_FRAGMENT */))
      ])
    ]))
  }), 256 /* UNKEYED_FRAGMENT */))
}