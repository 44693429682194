import {BaseApi} from '@/api/modules/base.api';
import {DoctorInterventionLimitModel} from '@/models/doctor-intervention-limit.model';
import BaseResponse from '@/api/response/base.response';
import {DoctorInterventionLimitStoreRequest} from '@/api/request/doctor-intervention-limit-store.request';
import {DoctorInterventionLimitUpdateRequest} from '@/api/request/doctor-intervention-limit-update.request';
import {ALL_DAYS_SELECT_VALUE, ORDER_RULE_CAN_NOT_BOOKED_LABEL, ORDER_RULE_CAN_NOT_BOOKED_VALUE} from '@/constants';
import {SelectOption} from '@/ui/components/Form/Select/select.types';
import {BaseRequest} from '@/api/request/base.request';
import {PatientHasDoctorModel} from "@/models/patient-has-doctor.model";
import {TimeLimitStoreRequest} from "@/api/request/time-limit-store.request";
import {TimeLimitModel} from "@/models/time-limit.model";
import {TimeLimitUpdateRequest} from "@/api/request/time-limit-update.request";
import {InterventionBlockModel} from "@/models/intervention-block.model";
import {InterventionBlockStoreRequest} from "@/api/request/intervention-block-store.request";
import {InterventionBlockUpdateRequest} from "@/api/request/intervention-block-update.request";

export class LimitationApi extends BaseApi<DoctorInterventionLimitModel> {
  async list(params: Partial<BaseRequest & DoctorInterventionLimitModel & Record<string, any>> = {}): Promise<BaseResponse<DoctorInterventionLimitModel[]>> {
    const response: BaseResponse<DoctorInterventionLimitModel[]> = await super.list(params);

    response.data = response.data.map(item => {
      if (item.intervention_id === null) {
        item.intervention_id = ORDER_RULE_CAN_NOT_BOOKED_VALUE as any
        item.intervention_name = ORDER_RULE_CAN_NOT_BOOKED_LABEL;
      }

      if ([null, 'day.day_'].includes(item.day_of_week as string)) {
        item.day_of_week = ALL_DAYS_SELECT_VALUE
      }

      item.from = this.formatTime(item.from);
      item.to = this.formatTime(item.to);

      return item;
    })

    return response;
  }

  create(data: DoctorInterventionLimitStoreRequest): Promise<BaseResponse<DoctorInterventionLimitModel>> {
    return super.create(
      this.initRequestData(data)
    );
  }

  update(id: number, data: DoctorInterventionLimitUpdateRequest): Promise<BaseResponse<DoctorInterventionLimitModel>> {
    return super.update(id, this.initRequestData(data));
  }

  async getDays(): Promise<Array<SelectOption>> {
    const response = await this.axios.get(`/limitation/day`) as BaseResponse<Record<string, number>>;
    const {data} = response;
    const dayArray: string[] = [];
    let allDayLabel = '';

    for (const label in data) {
      const value = data[label];

      if (value === null) {
        allDayLabel = label;
      } else {
        dayArray[value] = label;
      }
    }

    const firstDay = dayArray[0];
    dayArray.splice(0, 1);
    dayArray.push(firstDay);

    const options: SelectOption[] = Array.from(dayArray).map(day => ({
      label: day,
      value: data[day]
    }))
    options.unshift({label: allDayLabel, value: ALL_DAYS_SELECT_VALUE})
    return options;
  }

  getDoctorLimitations(userId: number): Promise<BaseResponse<PatientHasDoctorModel[]>> {
    return this.axios.get(`/limitation/doctor`, {params: {user: userId}});
  }

  inactivateDoctorLimitation(patientHasDoctorId: number) {
    return this.axios.delete('/limitation/doctor', {params: {user_has_doctor_id: patientHasDoctorId}})
  }

  getTimeLimits(params: BaseRequest & {doctor?: number} = {}): Promise<BaseResponse<TimeLimitModel[]>> {
    return this.axios.get('/limitation/time-limit', {params});
  }

  storeTimeLimit(data: TimeLimitStoreRequest): Promise<BaseResponse<TimeLimitModel>> {
    return this.axios.post('/limitation/time-limit', data);
  }

  updateTimeLimit(id: number, data: TimeLimitUpdateRequest): Promise<BaseResponse<TimeLimitModel>> {
    return this.axios.put(`/limitation/time-limit/${id}`, data);
  }

  getInterventionBlocks(params: any & {interventionName?: string} = {}): Promise<BaseResponse<InterventionBlockModel[]>> {
    if (!params.search) {
      delete params.search;
    }

    return this.axios.get('/limitation/intervention-block', {params});
  }

  storeInterventionBlock(data: InterventionBlockStoreRequest): Promise<BaseResponse<InterventionBlockModel>> {
    return this.axios.post('/limitation/intervention-block', data);
  }

  updateInterventionBlock(id: number, data: InterventionBlockUpdateRequest): Promise<BaseResponse<InterventionBlockModel>> {
    return this.axios.put(`/limitation/intervention-block/${id}`, data);
  }

  removeInterventionBlock(id: number): Promise<BaseResponse> {
    return this.axios.delete(`/limitation/intervention-block/${id}`);
  }

  private initRequestData(data: DoctorInterventionLimitStoreRequest | DoctorInterventionLimitUpdateRequest): DoctorInterventionLimitStoreRequest | DoctorInterventionLimitUpdateRequest {
    if (data.intervention_id === ORDER_RULE_CAN_NOT_BOOKED_VALUE as any) {
      data.intervention_id = null;
    }

    if (data.day_of_week === ALL_DAYS_SELECT_VALUE) {
      data.day_of_week = null;
    }

    return data;
  }

  private formatTime(time: string): string {
    const parts = time.split(':');
    parts.splice(parts.length - 1, 1);
    return parts.join(':');
  }
}
