
import AppMixin from "@/mixins/app.mixin";
import {mixins, Options} from "vue-property-decorator";
import {ListColumn} from "@/ui/components/List/list.types";
import {DoctorListItemModel} from "@/models/doctor-list-item.model";
import DividedLayout from "@/ui/layouts/DividedLayout.vue";
import List from "@/ui/components/List/List.vue";
import CustomIcon from "@/ui/components/CustomIcon.vue";
import Button from "@/ui/components/Button.vue";
import SelectInput from "@/ui/components/Form/Select/SelectInput.vue";
import TextInput from "@/ui/components/Form/TextInput.vue";
import LimitModal from "@/ui/modals/LimitModal.vue";
import CollapseList from "@/ui/components/CollapseList.vue";
import CollapseContainer from "@/ui/components/CollapseContainer.vue";
import InterventionBlockModal from "@/ui/modals/InterventionBlockModal.vue";
import ROUTES from "@/router/routes";

@Options({
  name: 'LimitPage',
  components: {CollapseContainer, CollapseList, TextInput, SelectInput, Button, CustomIcon, List, DividedLayout}
})
export default class LimitPage extends mixins(AppMixin) {
  columns: ListColumn<DoctorListItemModel>[] = [
    {field: 'doctor_name', title: 'doctors.field_doctor'},
    {field: 'specialist_clinics', title: 'doctors.field_specialist'},
    {field: 'interventions_count', title: 'doctors.field_service'}
  ];
  filters: Record<string, any> = {};
  filterParams: any = {};
  routes = ROUTES;

  onFilterFormSubmit(): void {
    this.filterParams = {
      ...this.filters
    };
  }

  openLimitModal(item: DoctorListItemModel): void {
    this.$modals.show(LimitModal, {
      doctorListItem: item
    })
  }

  initFilters(): void {
    this.filters = {search: null};
  }

  resetFilters(): void {
    this.initFilters();
    this.onFilterFormSubmit();
  }

  getSpecialistClinic(item: DoctorListItemModel): string[] {
    try {
      return item.specialist_clinics.map(clinic => clinic.specialist_clinic_name);
    } catch (e) {
      return [];
    }
  }

  getInterventions(item: DoctorListItemModel): string[] {
    try {
      const result = [];

      item.specialist_clinics.forEach(clinic => {
        result.push(
          ...clinic.interventions.map(intervention => intervention.intervention_name)
        )
      })

      return result;
    } catch (e) {
      return [];
    }
  }

  toDoctorListItem(item: DoctorListItemModel) {
    return item;
  }
}
