import Axios, {AxiosError, AxiosInstance, AxiosResponse} from 'axios'
import {ApiModules, createApiModules} from '@/api/modules'
import ROUTES from "src/router/routes";
import i18n from "src/i18n";
import {useNotifications} from '@/plugins/notifications';

declare const API_URL: string

let apiModules: ApiModules

export function useApi(): ApiModules {
  return apiModules
}

export default {
  install: (app, options) => {
    const axios: AxiosInstance = Axios.create({
      baseURL: API_URL,
      withCredentials: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })

    axios.interceptors.response.use(
      (response: AxiosResponse) => {
        const {data, config} = response;
        const isGetRequest = ['get', 'GET'].includes(config.method);

        if (data?.message && !isGetRequest) {
          useNotifications().showSuccess(data.message);
        }

        return Promise.resolve(data)
      },
      (error: AxiosError) => {
        let message: string;

        if (error.response.status === 401) {
          const router = app.config.globalProperties.$router
          useApi().auth.logout().then(() => router.replace(ROUTES.LOGIN))
        }

        if ((message = error?.response?.data?.message)) {
          useNotifications().showError(message);
        }

        return Promise.reject(error)
      }
    )

    axios.interceptors.request.use((request) => {
      request.headers['Content-Language'] = i18n.global.locale.value;
      return request;
    })

    apiModules = createApiModules(axios)

    app.config.globalProperties.$api = apiModules
  }
}

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $api: ApiModules;
  }
}
