
import {mixins, Options} from 'vue-property-decorator';
import AppMixin from '@/mixins/app.mixin';
import DividedLayout from '@/ui/layouts/DividedLayout.vue';
import TimeSummary from '@/ui/components/TimeSummary.vue';
import {MyBookedDateModel} from '@/models/my-booked-date.model';
import ConfirmModal from '@/ui/modals/ConfirmModal.vue';
import CalendarModal from '@/ui/modals/CalendarModal.vue';
import {AppActions} from '@/store/types';
import Button from '@/ui/components/Button.vue';
import CustomIcon from '@/ui/components/CustomIcon.vue';
import OrderBySelect from '@/ui/components/Form/OrderBySelect.vue';
import {OrderByPropertyNames} from '@/types';
import ROUTES from '@/router/routes';
import {getOrderOptionValue, parseOrderOptionValue} from '@/utils/form.util';
import {OrderByDirection} from '@/api/request/base.request';
import InlineLoader from '@/ui/components/InlineLoader.vue';

@Options({
  name: 'MyTimesPage',
  components: {InlineLoader, OrderBySelect, CustomIcon, Button, TimeSummary, DividedLayout}
})
export default class MyTimesPage extends mixins(AppMixin) {
  bookedDates: MyBookedDateModel[] = [];
  orderValue: string = getOrderOptionValue('from', OrderByDirection.asc);
  orderByProps: OrderByPropertyNames<MyBookedDateModel> = {
    from: 'Időpont',
  };
  loading = false;
  routes = ROUTES;
  firstLoad = true;

  created() {
    this.bookedDates = this.$store.state.myBookedDates ?? [];
    this.getBookedDates();
  }

  onOrderChange(): void {
    this.getBookedDates();
  }

  modify(bookedDate: MyBookedDateModel): void {
    this.$modals.show(CalendarModal, {
      bookedDate,
      onClose: () => this.$store.dispatch(AppActions.CLEAR_SELECTIONS),
      onSubmit: () => {
        this.getBookedDates();
      }
    })
  }

  remove(bookedDate: MyBookedDateModel): void {
    this.$modals.show(ConfirmModal, {
      message: 'Biztosan lemondja az időpontot?',
      onSubmit: async () => {
        try {
          this.loading = true;
          await this.$api.dokirex.deleteReservation({reservation_id: bookedDate.reservation_id})
          await this.getBookedDates()
          this.loading = false;
        } catch (e) {
          this.loading = false;
        }
      }
    })
  }

  isToday(date: string | Date): boolean {
    return this.moment(date).utc().isSame(new Date(), 'day')
  }

  getDate(date: string | Date): string {
    return this.moment(date).utc().format('YYYY. MMMM DD.')
  }

  getDay(date: string | Date): string {
    return this.moment(date).utc().format('dddd')
  }

  getTime(date: string | Date): string {
    return this.moment(date).utc().format('HH:mm')
  }

  getBookedDates() {
    const {orderBy, orderDirection} = parseOrderOptionValue(this.orderValue)
    this.loading = (this.firstLoad && !this.bookedDates.length) || !this.firstLoad;
    this.firstLoad = false;
    return this.$api.dokirex.getMyBookedDates({order_by: orderBy, order_type: orderDirection as any})
      .then(r => {
        this.bookedDates = r.data;
        return Promise.resolve(r);
      })
      .finally(() => this.loading = false)
  }
}
