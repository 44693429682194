import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteRecordRaw,
  RouterOptions
} from 'vue-router'
import MainLayout from '@/ui/layouts/MainLayout.vue'
import ROUTES from '@/router/routes'
import LoginPage from '@/ui/pages/LoginPage.vue'
import NotFoundPage from '@/ui/pages/NotFoundPage.vue'
import NoPermissionPage from '@/ui/pages/NoPermissionPage.vue'
import i18n from '@/i18n'
import {updateSiteTitle} from '@/utils/site.util'
import {appGuard} from "@/router/guard";
import UsersPage from "@/ui/pages/UsersPage.vue";
import {UserRole} from '@/enums/user.enum';
import RegistrationPage from '@/ui/pages/RegistrationPage.vue';
import DashboardPage from '@/ui/pages/DashboardPage.vue';
import MyTimesPage from '@/ui/pages/MyTimesPage.vue';
import ProfilePage from '@/ui/pages/ProfilePage.vue';
import DoctorsPage from '@/ui/pages/DoctorsPage.vue';
import ActivationPage from '@/ui/pages/ActivationPage.vue';
import PasswordResetRequestPage from '@/ui/pages/PasswordResetRequestPage.vue';
import PasswordResetPage from '@/ui/pages/PasswordResetPage.vue';
import RegistrationResultPage from '@/ui/pages/RegistrationResultPage.vue';
import {useStore} from '@/store';
import LimitPage from "@/ui/pages/LimitPage.vue";
import LimitBlockInnerPage from "@/ui/pages/LimitBlockInnerPage.vue";

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: MainLayout,
    redirect: `/${ROUTES.DASHBOARD}`,
    children: [
      {
        name: ROUTES.DASHBOARD,
        path: ROUTES.DASHBOARD,
        sensitive: true,
        component: DashboardPage,
        meta: {
          auth_required: true,
          auth_roles: Object.values(UserRole),
          in_navigation: true,
          icon: 'layout'
        }
      },
      {
        name: ROUTES.MY_TIMES,
        path: ROUTES.MY_TIMES,
        sensitive: true,
        component: MyTimesPage,
        meta: {
          auth_required: true,
          auth_roles: Object.values(UserRole),
          in_navigation: true,
          icon: 'calendar',
          badgeValue: () => {
            return useStore().state.myBookedDates.length.toString()
          },
          showBadge: (value) => {
            return !!value && value !== '0';
          }
        }
      },
      {
        name: ROUTES.PROFILE,
        path: ROUTES.PROFILE,
        sensitive: true,
        component: ProfilePage,
        meta: {
          auth_required: true,
          auth_roles: Object.values(UserRole),
          in_navigation: false
        }
      },
      {
        name: ROUTES.USERS,
        path: ROUTES.USERS,
        component: UsersPage,
        meta: {
          auth_required: true,
          auth_roles: [UserRole.SUPER_ADMIN, UserRole.ADMIN],
          in_navigation: true,
          onlyAdmin: true,
          icon: 'users'
        }
      },
      {
        name: ROUTES.DOCTORS,
        path: ROUTES.DOCTORS,
        component: DoctorsPage,
        meta: {
          auth_required: true,
          auth_roles: [UserRole.SUPER_ADMIN, UserRole.ADMIN],
          in_navigation: true,
          onlyAdmin: true,
          icon: 'doctor'
        }
      },
      {
        name: ROUTES.LIMITATIONS,
        path: ROUTES.LIMITATIONS,
        component: LimitPage,
        meta: {
          auth_required: true,
          auth_roles: [UserRole.SUPER_ADMIN, UserRole.ADMIN],
          in_navigation: true,
          onlyAdmin: true,
          icon: 'doctor'
        },
      },
      {
        path: `${ROUTES.LIMITATIONS}/${ROUTES.LIMITATIONS_BLOCK_INNER}`,
        redirect: {
          name: ROUTES.LIMITATIONS
        }
      },
      {
        name: ROUTES.LIMITATIONS_BLOCK_INNER,
        path: `${ROUTES.LIMITATIONS}/${ROUTES.LIMITATIONS_BLOCK_INNER}/:doctorId`,
        component: LimitBlockInnerPage,
        meta: {
          auth_required: true,
          auth_roles: [UserRole.SUPER_ADMIN, UserRole.ADMIN],
          in_navigation: false,
          onlyAdmin: true,
        },
      }
    ]
  },
  {
    name: ROUTES.LOGIN,
    path: `/${ROUTES.LOGIN}`,
    component: LoginPage,
    meta: {
      auth_required: false,
      in_navigation: false,
      auth_roles: Object.values(UserRole)
    }
  },
  {
    name: ROUTES.ACTIVATION,
    path: `/${ROUTES.ACTIVATION}/:code`,
    component: ActivationPage,
    meta: {
      auth_required: false,
      in_navigation: false,
      auth_roles: Object.values(UserRole)
    }
  },
  {
    name: ROUTES.PASSWORD_RESET,
    path: `/${ROUTES.PASSWORD_RESET}`,
    component: PasswordResetRequestPage,
    meta: {
      auth_required: false,
      in_navigation: false,
      auth_roles: Object.values(UserRole)
    }
  },
  {
    name: ROUTES.PASSWORD_RESET_REQUEST,
    path: `/${ROUTES.PASSWORD_RESET}/:code`,
    component: PasswordResetPage,
    meta: {
      auth_required: false,
      in_navigation: false,
      auth_roles: Object.values(UserRole)
    }
  },
  {
    name: ROUTES.REGISTER,
    path: `/${ROUTES.REGISTER}`,
    component: RegistrationPage,
    meta: {
      auth_required: false,
      in_navigation: false,
      auth_roles: Object.values(UserRole)
    }
  },
  {
    name: ROUTES.REGISTER_RESULT,
    path: `/${ROUTES.REGISTER_RESULT}`,
    component: RegistrationResultPage,
    meta: {
      auth_required: false,
      in_navigation: false,
      auth_roles: Object.values(UserRole)
    }
  },
  {
    name: ROUTES.NO_PERMISSION,
    path: `/${ROUTES.NO_PERMISSION}`,
    props: true,
    component: NoPermissionPage,
    meta: {
      auth_required: false,
      in_navigation: false,
      auth_roles: Object.values(UserRole)
    }
  },
  {
    name: ROUTES.NOT_FOUND,
    path: '/:catchAll(.*)',
    component: NotFoundPage,
    meta: {
      auth_required: false,
      in_navigation: false,
      auth_roles: Object.values(UserRole)
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
} as RouterOptions);

router.beforeEach(appGuard)

router.afterEach((to: RouteLocationNormalized) => {
  let t = i18n.global.t
  updateSiteTitle(to.name ? t('page.' + to.name.toString().replaceAll('-', '_')) : '')
})

export default router
