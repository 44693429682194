
import {UserRole} from '@/enums/user.enum';
import AppMixin from '@/mixins/app.mixin';
import {Emit, mixins, Options} from 'vue-property-decorator';
import CustomIcon from '@/ui/components/CustomIcon.vue';

@Options({
  name: 'RouteNavigation',
  components: {CustomIcon}
})
export default class RouteNavigation extends mixins(AppMixin) {
  @Emit('routeChange')
  emitRouteChange(): void {}

  routes: {
    label: string;
    to: string;
    show: boolean;
    icon?: string;
    badgeValue?: () => string;
    showBadge?: (value: any) => boolean;
  }[] = [];

  created() {
    const isLoggedIn = () => this.$store.getters.userLoggedIn;
    const userHasRouteRoles = (roles: UserRole[]) => this.$store.getters.userInRole(roles);

    this.routes = this.$router.getRoutes().filter(route => route.meta.in_navigation).map(route => {
      return {
        label: route.name as string,
        to: route.name as string,
        icon: route.meta?.icon ?? null,
        show: ((isLoggedIn() && (!route.meta.auth_roles || (route.meta.auth_roles && userHasRouteRoles(route.meta.auth_roles)))) || !route.meta.auth_required),
        badgeValue: route.meta.badgeValue ?? null,
        showBadge: route.meta.showBadge ?? null
      };
    })
  }
}
