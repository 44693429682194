
import {ModalMixin} from '@/mixins/modal.mixin';
import {mixins, Options, Prop, Ref} from 'vue-property-decorator';
import Modal from '@/ui/components/Modal/Modal.vue';
import Button from '@/ui/components/Button.vue';

@Options({
  name: 'ConfirmModal',
  components: {Button, Modal}
})
export default class ConfirmModal extends mixins(ModalMixin) {
  @Prop({type: String})
  title!: string;

  @Prop({type: String, required: true})
  message!: string;

  confirm() {
    this.modal.close();
    this.onSubmit();
  }
}
