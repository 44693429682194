
import AppMixin from '@/mixins/app.mixin';
import {Emit, mixins, Options} from 'vue-property-decorator';
import CustomIcon from '@/ui/components/CustomIcon.vue';
import Button from '@/ui/components/Button.vue';
import {acceptCookies} from '@/utils/site.util';
import {PRIVACY_STATEMENT_DOC_PATH} from '@/constants';

@Options({
  name: 'CookieBox',
  components: {Button, CustomIcon}
})
export default class CookieBox extends mixins(AppMixin) {
  @Emit('destroy')
  emitDestroy(): void {}

  show = false;
  readonly privacyPath = PRIVACY_STATEMENT_DOC_PATH;

  mounted() {
    setTimeout(() => {
      this.show = true;
    }, 0)
  }

  onTransitionEnd(): void {
    if (!this.show) {
      this.emitDestroy();
    }
  }

  accept(): void {
    acceptCookies();
    this.show = false;
  }

  hide(): void {
    this.show = false;
  }
}
