import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, vModelText as _vModelText, withKeys as _withKeys, mergeProps as _mergeProps, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-712ef5c9"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "input-group" }
const _hoisted_3 = {
  key: 0,
  class: "input-group-text"
}
const _hoisted_4 = ["id", "placeholder", "disabled", "readonly", "autocomplete"]
const _hoisted_5 = {
  key: 1,
  class: "input-group-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_field_messages = _resolveComponent("field-messages")!
  const _component_Field = _resolveComponent("Field")!
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createBlock(_component_Field, {
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.value) = $event)),
    name: _ctx.name || _ctx.uuid,
    label: _ctx.getFieldLabel(),
    rules: _ctx.validationRules,
    as: "div"
  }, {
    default: _withCtx(({errors, field}) => [
      _createElementVNode("fieldset", {
        class: _normalizeClass(["form-field", _ctx.getFormFieldClassNames(errors)])
      }, [
        (_ctx.label)
          ? (_openBlock(), _createElementBlock("label", {
              key: 0,
              class: "form-label",
              for: _ctx.uuid
            }, _toDisplayString(_ctx.getLabel()), 9 /* TEXT, PROPS */, _hoisted_1))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.$slots.prefix)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                _renderSlot(_ctx.$slots, "prefix", {}, undefined, true)
              ]))
            : _createCommentVNode("v-if", true),
          _withDirectives(_createElementVNode("input", _mergeProps({
            ref: "inputEl",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event))
          }, field, {
            id: _ctx.uuid,
            placeholder: _ctx.placeholder,
            disabled: _ctx.disabled,
            readonly: _ctx.readonly,
            autocomplete: _ctx.autocomplete,
            class: ['form-control', ..._ctx.classNames, ...(errors.length ? ['is-invalid'] : [])].join(' '),
            type: "text",
            onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onFocus($event))),
            onFocusout: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onFocusOut($event))),
            onKeydown: [
              _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.onUpKeydown($event)), ["up"])),
              _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.onDownKeydown($event)), ["down"])),
              _cache[5] || (_cache[5] = _withKeys(($event: any) => (_ctx.enterKeydownListener($event)), ["enter"]))
            ]
          }), null, 16 /* FULL_PROPS */, _hoisted_4), [
            [_vModelText, _ctx.value],
            [_directive_maska, _ctx.getNumberMask()]
          ]),
          _renderSlot(_ctx.$slots, "buttonSuffix", {}, undefined, true),
          (_ctx.$slots.suffix)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                _renderSlot(_ctx.$slots, "suffix", {}, undefined, true)
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _createVNode(_component_field_messages, {
          "info-message": _ctx.infoMessage,
          errors: errors
        }, null, 8 /* PROPS */, ["info-message", "errors"])
      ], 2 /* CLASS */)
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["modelValue", "name", "label", "rules"]))
}