
import {Options} from 'vue-property-decorator';
import Modal from '@/ui/components/Modal/Modal.vue';
import SelectInput from '@/ui/components/Form/Select/SelectInput.vue';
import TextInput from '@/ui/components/Form/TextInput.vue';
import {UserStoreRequest} from '@/api/request/user-store.request';
import {UserModel} from '@/models/user.model';
import {CuModalMixin} from '@/mixins/cu-modal.mixin';
import PasswordInput from '@/ui/components/Form/PasswordInput.vue';
import {SelectOption} from '@/ui/components/Form/Select/select.types';
import {NationalityModel} from '@/models/nationality.model';
import {AppGetters} from '@/store/types';
import {USER_ROLE_SELECT_OPTIONS, USER_SEX_SELECT_OPTIONS} from '@/constants';
import { getRequestData } from 'src/utils/api.util';
import Button from '@/ui/components/Button.vue';
import {UserUpdateRequest} from '@/api/request/user-update.request';
import { isHungarian } from 'src/utils/form.util';

@Options({
  name: 'UserModal',
  components: {Button, PasswordInput, TextInput, SelectInput, Modal}
})
export default class UserModal extends CuModalMixin<UserModel> {
  userRoleOptions = USER_ROLE_SELECT_OPTIONS;
  nationalitySelectOptions: Array<SelectOption<NationalityModel>> = [];
  userSexSelectOptions: Array<SelectOption> = USER_SEX_SELECT_OPTIONS;

  itemFormData: UserStoreRequest & UserUpdateRequest = {
    name: null,
    email: null,
    role: null,
    phone: null,
    born_date: null,
    sex_id: null,
    social_security_number: null,
    is_foreign: null,
    nationality_id: null,
    password: null,
    password_confirmation: null,
    password_new: null,
    password_new_confirmation: null
  };
  tajNumber: string = null;

  get isEdit(): boolean {
    return !!this.item;
  }

  created() {
    super.created();
    this.nationalitySelectOptions = this.$store.getters[AppGetters.NATIONALITY_SELECT_OPTIONS];
    this.tajNumber = this.itemFormData.social_security_number;
  }

  getRequestData<T extends object, D extends object>(formData: D, item?: T, nullable: boolean = false, clearSameData: boolean = true): D {
    const {born_date, social_security_number, password, password_new} = formData as UserStoreRequest & UserUpdateRequest;

    (formData as UserStoreRequest & UserUpdateRequest).password = password ? password : null;
    (formData as UserStoreRequest & UserUpdateRequest).password_new = password_new ? password_new : null;

    return {
      ...getRequestData(formData, item),
      social_security_number: this.isHungarian() ? this.tajNumber : social_security_number,
      born_date: born_date ? this.moment(born_date, this.dateInputFormat).format('YYYY-MM-DD') : null,
      is_foreign: !isHungarian(this.nationalitySelectOptions, this.itemFormData.nationality_id)
    };
  }

  getValidationRules(hasRequired: boolean, ...rules: string[]): string {
    return [
      hasRequired && !this.isEdit ? 'required' : '',
      ...rules
    ].filter(rule => !!rule).join('|');
  }

  isHungarian(): boolean {
    const nationality = this.nationalitySelectOptions.find(item => item.value === this.itemFormData.nationality_id);

    if (nationality) {
      return nationality.label === 'magyar';
    }

    return false;
  }
}
