
import {Emit, mixins, Options} from 'vue-property-decorator';
import {InputMixin} from '@/mixins/input.mixin';
import FieldMessages from '@/ui/components/Form/FieldMessages.vue';

@Options({
  name: 'Checkbox',
  components: {FieldMessages}
})
export default class Checkbox extends mixins(InputMixin) {
  @Emit('change')
  emitChange(value: boolean): void {}
}
