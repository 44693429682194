import {configure, defineRule} from 'vee-validate';
import Rules from '@vee-validate/rules';
import {localize, setLocale} from '@vee-validate/i18n';
import i18n from 'src/i18n';
import en from '@vee-validate/i18n/dist/locale/en.json';
import hu from '@vee-validate/i18n/dist/locale/hu.json';
import {appMoment} from '@/utils/moment.util';

Object.keys(Rules).forEach(rule => {
  defineRule(rule, Rules[rule]);
});

defineRule('file_size', Rules.size);

configure({
  generateMessage: localize({
    en,
    hu,
  }),
});

setLocale('hu');

defineRule('confirmed', (value, [other]) => {
  if (value !== other) {
    return i18n.global.t('validation_message.equal') as string
  }

  return true;
});

defineRule('date', (value) => {
  const message = 'Érvénytelen dátum!';

  try {
    if (!appMoment(value, 'YYYY.MM.DD').isValid()) {
      return message;
    }
  } catch (e) {
    return message;
  }

  return true;
})
