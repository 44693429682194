
import {mixins, Options, Prop, Ref} from "vue-property-decorator";
import {ModalMixin} from "@/mixins/modal.mixin";
import Modal from "@/ui/components/Modal/Modal.vue";
import Button from "@/ui/components/Button.vue";
import {DoctorListItemModel} from "@/models/doctor-list-item.model";
import {ListColumn} from "@/ui/components/List/list.types";
import {InterventionModel} from "@/models/intervention.model";
import List from "@/ui/components/List/List.vue";
import Radio from "@/ui/components/Form/Radio.vue";
import {TimeLimit} from "@/enums/time-limit.enum";
import {TimeLimitModel} from "@/models/time-limit.model";

type InterventionWithSpecialistClinic = InterventionModel & {specialist_clinic_name: string};
type InterventionListItem = InterventionWithSpecialistClinic & {time_limit: TimeLimit};

@Options({
  name: 'LimitModal',
  components: {Radio, List, Button, Modal}
})
export default class LimitModal extends mixins(ModalMixin) {
  @Ref('list')
  readonly list!: InstanceType<typeof List>;

  @Prop({
    type: Object as () => DoctorListItemModel,
    required: true
  })
  readonly doctorListItem!: DoctorListItemModel;

  columns: ListColumn<InterventionListItem>[] = [
    {field: 'intervention_name', title: 'doctors.field_service'}
  ];
  interventionListItems: InterventionListItem[] = [];
  timeLimits = TimeLimit;
  interventionTimeLimits: TimeLimitModel[] = [];
  listItemsInited = false;
  selectedInterventionListItems: InterventionListItem[] = [];

  created() {
    this.fetchTimeLimits();
  }

  getItemsGetter() {
    return () => Promise.resolve({data: this.interventionListItems})
  }

  async onTimeLimitChange(item: InterventionModel, timeLimit: TimeLimit): Promise<void> {
    try {
      const existTimeLimit = this.getExistTimeLimit(item);
      this.loading = true;

      if (!existTimeLimit) {
        await this.$api.limitation.storeTimeLimit({
          doctor_id: this.doctorListItem.doctor_id,
          intervention_id: item.intervention_id,
          time_limit: timeLimit
        })
      } else {
        await this.$api.limitation.updateTimeLimit(existTimeLimit.id, {
          doctor_id: this.doctorListItem.doctor_id,
          intervention_id: item.intervention_id,
          time_limit: timeLimit
        })
      }

      await this.fetchTimeLimits();
      this.list.refreshItems();
    } finally {
      this.loading = false;
    }
  }

  async bulkTimeLimitChange(timeLimit: TimeLimit): Promise<void> {
    try {
      this.loading = true;

      for (const intervention of this.selectedInterventionListItems) {
        const existTimeLimit = this.getExistTimeLimit(intervention);

        if (!existTimeLimit) {
          await this.$api.limitation.storeTimeLimit({
            doctor_id: this.doctorListItem.doctor_id,
            intervention_id: intervention.intervention_id,
            time_limit: timeLimit
          })
        } else {
          await this.$api.limitation.updateTimeLimit(existTimeLimit.id, {
            doctor_id: this.doctorListItem.doctor_id,
            intervention_id: intervention.intervention_id,
            time_limit: timeLimit
          })
        }
      }

      if (this.selectedInterventionListItems.length) {
        await this.fetchTimeLimits();
        this.list.refreshItems();
        this.list.toggleAllSelection(false);
      }
    } finally {
      this.loading = false;
    }
  }

  getInterventions(): InterventionWithSpecialistClinic[] {
    try {
      const interventions: InterventionWithSpecialistClinic[] = [];

      this.doctorListItem.specialist_clinics.forEach(clinic => {
        const interventionsWithSpecialistClinic: InterventionWithSpecialistClinic[] = clinic.interventions.map(intervention => ({
          ...intervention,
          specialist_clinic_name: clinic.specialist_clinic_name
        }))
        interventions.push(...interventionsWithSpecialistClinic)
      })

      return interventions;
    } catch (e) {
      return [];
    }
  }

  toInterventionListItem(item: InterventionListItem) {
    return item;
  }

  async fetchTimeLimits(): Promise<void> {
    try {
      this.loading = true;
      this.interventionTimeLimits = (await this.$api.limitation.getTimeLimits({doctor: this.doctorListItem.doctor_id}))?.data || [];
      this.initInterventionListItems();
    } finally {
      this.loading = false;
    }
  }

  private initInterventionListItems(): void {
    const items: InterventionListItem[] = [];

    this.getInterventions().forEach(intervention => {
      const existTimeLimit = this.getExistTimeLimit(intervention);

      items.push({
        ...intervention,
        time_limit: existTimeLimit?.time_limit || TimeLimit.MONTHLY
      })
    })

    this.interventionListItems = items;
    this.listItemsInited = true;
  }

  private getExistTimeLimit(intervention: InterventionModel): TimeLimitModel {
    return this.interventionTimeLimits.find(
      limit => limit.doctor_id === this.doctorListItem.doctor_id && limit.intervention_id === intervention.intervention_id
    );
  }
}
