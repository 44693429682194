
import router from '@/router'
import { defineComponent } from 'vue'
import { updateSiteTitle } from "@/utils/site.util"
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'LocaleSelect',

  setup(props, context) {
    const { locale, availableLocales } = useI18n();

    return {
      locale, availableLocales
    }
  },

  watch: {
    locale: function() {
      const currentRoute = router.currentRoute.value;
      updateSiteTitle(currentRoute.name ? this.$t('page.' + currentRoute.name.toString()) : '');
    }
  }

})
