
import AppMixin from '@/mixins/app.mixin';
import {mixins, Options, Prop, Watch} from 'vue-property-decorator';

type CustomIcons = 'bacterium' | 'calendar' | 'chat' | 'clock' | 'doctor' | 'layout' | 'logout' | 'pencil' |
                   'stethoscope' | 'trash' | 'user' | 'users' | 'check' | 'plus' | 'copy' | 'cookie'

@Options({
  name: 'CustomIcon'
})
export default class CustomIcon extends mixins(AppMixin) {
  @Prop({
    type: String as () => CustomIcons,
    required: true
  })
  readonly icon!: CustomIcons;

  @Prop({
    type: [String, Number],
    default: () => null
  })
  readonly size!: number;

  @Watch('icon', {immediate: true})
  onIconChange(): void {
    this.loadContent()
  }

  content: string = '';

  loadContent(): void {
    try {
      this.content = require(`@/assets/img/icons/${this.icon}.svg`);

      if (this.size) {
        this.content = this.content.replace('<svg', `<svg width="${this.size}"`)
      }
    } catch (e) {
      console.warn(e);
    }
  }
}
