
import AppMixin from '@/mixins/app.mixin';
import {Emit, Inject, mixins, Model, Options, Prop, Ref, Watch} from 'vue-property-decorator';
import {SelectOption} from '@/ui/components/Form/Select/select.types';
import CollapseContainer from '@/ui/components/CollapseContainer.vue';
import {generateUUID} from '@/utils/site.util';
import TextInput from '@/ui/components/Form/TextInput.vue';

@Options({
  name: 'CollapseList',
  components: {TextInput}
})
export default class CollapseList extends mixins(AppMixin) {
  @Model('modelValue', {default: () => null})
  value!: any;

  @Ref('collapseBody')
  collapseBody!: HTMLElement;

  @Prop({type: String, default: () => ''})
  submitLabel!: string;

  @Prop({type: Array, default: () => []})
  items!: SelectOption[];

  @Prop({type: Boolean, default: () => false})
  isOpen!: boolean;

  @Prop({type: Boolean, default: () => false})
  multiple!: boolean;

  @Prop({type: Boolean, default: () => false})
  loading!: boolean;

  @Prop({type: Boolean, default: () => true})
  showCounter!: boolean;

  @Prop({type: Boolean, default: () => true})
  searchable!: boolean;

  @Inject()
  collapseContainer!: InstanceType<typeof CollapseContainer>;

  @Emit('selectionChange')
  emitSelectionChange(items: SelectOption | SelectOption[]): void {
  }

  @Emit('submit')
  emitSubmit(): void {
    const nextCollapse = this.collapseContainer.collapses[this.getCollapseIndex() + 1] || null;
    if (nextCollapse) {
      this.computedIsOpen = false;
      nextCollapse.computedIsOpen = true;
    }
  }

  @Emit('stateChange')
  emitStateChange(state: boolean): void {}

  @Watch('items', {immediate: true})
  onItemsChange(): void {
    this.computedItems = Array.from(this.items);
    this.initSelectedItems();
    setTimeout(() => {
      this.$forceUpdate();
    }, 0)
  }

  computedIsOpen = false;
  computedItems: SelectOption[] = [];
  selectedItems: SelectOption[] = [];
  uuid = generateUUID();
  isMounted = false;
  searchValue: string = null;

  created() {
    this.computedIsOpen = this.isOpen;
    this.computedItems = Array.from(this.items);

    if (!this.collapseContainer) {
      console.warn(`[${CollapseList.name}]: ${CollapseContainer.name} parent element is missing!`);
      return;
    }

    this.collapseContainer.collapses.push(this);
  }

  mounted() {
    this.isMounted = true;
  }

  beforeUnmount() {
    const index = this.getCollapseIndex();

    if (index > -1) {
      this.collapseContainer.collapses.splice(index, 1);
    }
  }

  toggleIsOpen(): void {
    if (!this.collapseContainer.multiple && !this.computedIsOpen) {
      this.collapseContainer.collapses.forEach(item => item.computedIsOpen = false);
    }

    this.computedIsOpen = !this.computedIsOpen;
    this.emitStateChange(this.computedIsOpen);
  }

  getSelectedItemsLabel(): string {
    return this.selectedItems.map(item => item.label).join(', ')
  }

  getBodyScrollHeight(): number {
    const fallback = 264;
    const height = this.collapseBody?.scrollHeight ?? fallback;
    return height < fallback ? fallback : height;
  }

  toggleItemSelection(item: SelectOption): void {
    if (this.isSelected(item)) {
      const index = this.selectedItems.findIndex(_item => _item.value === item.value);

      if (index > -1) {
        this.selectedItems.splice(index, 1);
      }
    } else {
      if (!this.multiple && this.selectedItems.length) {
        this.selectedItems = [item];
      } else {
        this.selectedItems.push(item);
      }
    }

    const value = this.multiple
      ? this.selectedItems
      : this.selectedItems.length ? this.selectedItems[0] : null

    this.value = Array.isArray(value) ? value.map(item => item.value) : value?.value ?? null;
    this.emitSelectionChange(value);
  }

  isSelected(item: SelectOption): boolean {
    return !!this.selectedItems.find(_item => _item.value === item.value);
  }

  search(): void {
    if (this.searchValue) {
      this.computedItems = Array.from(this.items)
        .filter(item => (item.label as string).toLowerCase().includes(this.searchValue.trim().toLowerCase()))
    } else {
      this.computedItems = Array.from(this.items);
    }
  }

  private initSelectedItems(): void {
    const value = this.value
      ? Array.isArray(this.value) ? this.value : [this.value]
      : [];

    this.selectedItems = this.items.filter(item => value.includes(item.value));
  }

  private getCollapseIndex(): number {
    return this.collapseContainer.collapses.findIndex(item => item.uuid === this.uuid);
  }
}
