
import {Emit, mixins, Options, Prop, Watch} from 'vue-property-decorator';
import {InputMixin} from '@/mixins/input.mixin';
import {SelectOption, SelectOptionsFetcher} from '@/ui/components/Form/Select/select.types';
import FieldMessages from '@/ui/components/Form/FieldMessages.vue';
import Multiselect from '@vueform/multiselect';
import '@vueform/multiselect/themes/default.css';

@Options({
  name: 'SelectInput',
  components: {Multiselect, FieldMessages}
})
export default class SelectInput extends mixins(InputMixin) {
  @Prop({type: Array, default: () => []})
  options!: SelectOption[];

  @Prop({type: Boolean, default: () => false})
  multiple!: boolean;

  @Prop({type: Boolean, default: () => false})
  clearOnSelect!: boolean;

  @Prop({type: Boolean, default: () => true})
  closeOnSelect!: boolean;

  @Prop({type: Boolean, default: () => true})
  hideSelected!: boolean;

  @Prop({type: Boolean, default: () => false})
  createTag!: boolean;

  @Prop({type: Boolean, default: () => true})
  appendNewTag!: boolean;

  @Prop({type: Boolean, default: () => false})
  searchable!: boolean;

  @Prop({type: Boolean, default: () => true})
  clearable!: boolean;

  @Prop({type: Boolean, default: () => false})
  loading!: boolean;

  @Prop({type: Boolean, default: () => false})
  groups!: boolean;

  @Prop({type: Number, default: () => 0})
  min!: number;

  @Prop({type: String, default: () => 'A lista üres'})
  noOptionsText!: string;

  @Prop({type: String, default: () => 'Nincs találat'})
  noResultsText!: string;

  @Prop({type: Function, default: null})
  optionsFetcher!: SelectOptionsFetcher;

  @Emit('select')
  emitSelect(option: SelectOption): void {
  }

  @Emit('deselect')
  emitDeselect(option: SelectOption): void {
  }

  @Emit('selectionChange')
  emitSelectionChange(option: SelectOption): void {
  }

  @Watch('options', {deep: true})
  onOptionsChange(): void {
    this.selectOptions = Array.from(this.options);
  }

  selectOptions: SelectOption[] = [];
  computedLoading = false;
  showInput = false;
  computedDisabled = true;

  created() {
    this.refreshOptions();
    this.checkOptionExist(this.selectOptions);
  }

  onSelected(event): void {
    this.emitSelect(event);
    this.emitSelectionChange(event);
  }

  onDeselected(event): void {
    this.emitDeselect(this.value ? event : null);
    this.emitSelectionChange(this.value ? event : null);
  }

  refreshOptions(): void {
    this.computedDisabled = true;
    this.showInput = false;
    this.selectOptions = this.options || [];

    if (this.optionsFetcher instanceof Function) {
      this.computedLoading = true;
      this.optionsFetcher()
        .then(r => {
          this.checkOptionExist(r);
          this.selectOptions = r;
          this.showInput = true;
          this.computedDisabled = this.disabled;
        })
        .finally(() => {
          this.computedLoading = false;
        });
    } else {
      this.showInput = true;
      this.computedDisabled = this.disabled;
    }
  }

  private checkOptionExist(selectOptions: SelectOption[]): void {
    if (this.value && selectOptions.length) {
      if (!this.multiple) {
        if (!selectOptions?.find(option => option.value === this.value)) {
          this.value = null;
        }
      } else {
        this.value = (this.value as any[]).filter(value => selectOptions?.map(option => option.value).includes(value));
      }
    }
  }
}
