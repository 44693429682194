import {Model, Prop, Watch} from 'vue-property-decorator';
import {useInput} from '@/plugins/input';
import AppMixin from '@/mixins/app.mixin';
import {setup} from 'vue-class-component';
import {inject} from 'vue';
import AppForm from '@/ui/components/Form/AppForm.vue';

export class InputMixin extends AppMixin {
  @Model('modelValue', {default: () => null})
  value!: string | number | Date | any[];

  @Prop({required: false, type: String})
  label!: string;

  @Prop({required: false, type: String})
  name!: string;

  @Prop({required: false, type: String})
  validationRules!: string;

  @Prop({required: false, type: String, default: () => ''})
  placeholder!: string;

  @Prop({required: false, type: Array, default: () => []})
  classNames!: string[];

  @Prop({required: false, type: Boolean, default: () => false})
  disabled!: boolean;

  @Prop({required: false, type: Boolean, default: () => false})
  readonly!: boolean;

  @Prop({required: false, type: Boolean, default: () => true})
  tabByEnter!: boolean;

  @Prop({type: String})
  infoMessage!: string;

  @Prop({type: Boolean, default: () => false})
  inline!: boolean;

  @Prop({type: String, default: () => 'off'})
  autocomplete!: string;

  @Watch('value', {immediate: true})
  onValueChange() {
    if (this.value) {
      if (this.formFieldClasses.indexOf('filled') === -1) {
        this.formFieldClasses.push('filled');
      }
    } else {
      const index = this.formFieldClasses.indexOf('filled');
      if (index > -1) {
        this.formFieldClasses.splice(index, 1);
      }
    }
  }

  setupContext = setup(() => {
    const appForm: InstanceType<typeof AppForm> = inject(AppForm.name);
    return {appForm};
  });
  formFieldClasses: string[] = [];
  protected uuid: string;
  protected computedValue: string | number | Date | any[];

  enterKeydownListener(e: KeyboardEvent) {
    if (!this.tabByEnter) {
      return;
    }

    let self: HTMLElement = e.target as any, form = self.closest('form'), focusable: any[], next;
    focusable = Array.from(form?.querySelectorAll('input,a,select,textarea,[contenteditable]') ?? []);
    next = focusable[focusable.indexOf(self) + 1];

    if (next) {
      next.focus();
    } else {
      e.preventDefault();
      form?.requestSubmit();
    }

    return false;
  };

  created() {
    this.init();
  }

  onFocus(event) {
    if (this.formFieldClasses.indexOf('focused') === -1) {
      this.formFieldClasses.push('focused');
    }
  }

  onFocusOut(event) {
    const index = this.formFieldClasses.indexOf('focused');
    if (index > -1) {
      this.formFieldClasses.splice(index, 1);
    }
  }

  getFormFieldClassNames(errors?: string[]): string[] {
    return [
      errors?.length || !!this.infoMessage ? 'has-message' : '', ...this.formFieldClasses,
      this.inline ? 'form-field--inline' : '',
      !this.label ? 'form-field--no-label' : '',
      this.disabled ? 'form-field--disabled' : ''
    ]
  }

  getFieldLabel(): string {
    return this.label?.toLowerCase() || 'mező';
  }

  getLabel(): string {
    if (this.validationRules?.includes('required')) {
      return this.label ? `${this.label}*` : this.label
    }

    return this.label;
  }

  protected init(): void {
    const {uuid} = useInput();
    this.uuid = `input-${uuid}`;
    this.computedValue = this.value;
  }
}
