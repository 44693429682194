import {UserModel} from '@/models/user.model';
import {Store} from 'vuex';
import {UserRole} from '@/enums/user.enum';
import {SpecialistClinicModel} from '@/models/specialist-clinic.model';
import {DoctorModel} from '@/models/doctor.model';
import {InterventionModel} from '@/models/intervention.model';
import {FreeTimeSegmentModel} from '@/models/free-time-segment.model';
import {NationalityModel} from '@/models/nationality.model';
import {SelectOption} from '@/ui/components/Form/Select/select.types';
import {MyBookedDateModel} from '@/models/my-booked-date.model';

export interface AppState {
  user: UserModel;
  selections: {
    specialistClinic: SpecialistClinicModel,
    doctor: DoctorModel,
    intervention: InterventionModel,
    time: {
      day_id: number,
      date: Date,
      time: FreeTimeSegmentModel['free_intervals'][number]
    }
  };
  nationalities: Array<NationalityModel>,
  dayOptions: Array<SelectOption>,
  lastRegisteredEmail: string,
  myBookedDates: Array<MyBookedDateModel>,
}

export enum AppGetters {
  NATIONALITY_SELECT_OPTIONS = 'APP_NATIONALITY_SELECT_OPTIONS'
}

export enum AppActions {
  SET_LOGGED_USER = 'APP_SET_LOGGED_USER',
  INVALIDATE_LOGGED_USER = 'APP_INVALIDATE_LOGGED_USER',
  REFRESH_LOGGED_USER = 'APP_REFRESH_LOGGED_USER',
  SET_SPECIALIST_CLINIC = 'APP_SET_SPECIALIST_CLINIC',
  SET_DOCTOR = 'APP_SET_DOCTOR',
  SET_INTERVENTION = 'APP_SET_INTERVENTION',
  SET_TIME = 'APP_SET_TIME',
  CLEAR_SELECTIONS = 'APP_CLEAR_SELECTIONS',
  GET_NATIONALITIES = 'APP_GET_NATIONALITIES',
  GET_DAYS = 'APP_GET_DAYS',
  SET_LAST_REGISTERED_EMAIL = 'APP_SET_LAST_REGISTERED_EMAIL',
  FETCH_MY_BOOKED_DATES = 'APP_FETCH_MY_BOOKED_DATES',
}

export enum AppMutations {
  SET_LOGGED_USER = 'APP_SET_LOGGED_USER',
  SET_SPECIALIST_CLINIC = 'APP_SET_SPECIALIST_CLINIC',
  SET_DOCTOR = 'APP_SET_DOCTOR',
  SET_INTERVENTION = 'APP_SET_INTERVENTION',
  SET_TIME = 'APP_SET_TIME',
  CLEAR_SELECTIONS = 'APP_CLEAR_SELECTIONS',
  SET_NATIONALITIES = 'APP_SET_NATIONALITIES',
  SET_DAYS = 'APP_SET_DAYS',
  SET_LAST_REGISTERED_EMAIL = 'APP_SET_LAST_REGISTERED_EMAIL',
  SET_MY_BOOKED_DATES = 'APP_SET_MY_BOOKED_DATES',
}

export interface AppStore extends Store<AppState> {
  getters: {
    userInRole(roles: UserRole | UserRole[]): boolean;
    userLoggedIn: boolean;
  }
}
