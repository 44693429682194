
import AppMixin from '@/mixins/app.mixin';
import {Emit, mixins, Options, Ref} from 'vue-property-decorator';
import {Form, FormContext, FormState, FormValidationResult, ValidationResult} from 'vee-validate';
import {provide} from 'vue';
import {setup} from 'vue-class-component';

@Options({
  name: 'AppForm',
  components: {Form}
})
export default class AppForm extends mixins(AppMixin) implements Partial<FormContext> {
  @Ref('vForm')
  vForm: FormContext = null;

  @Emit('formSubmit')
  emitSubmit(): void {}

  setupContext = setup(() => {
    provide(AppForm.name, this);
    return {};
  })

  resetForm(state: Partial<FormState<Record<string, any>>> | undefined): void {
    return this.vForm.resetForm(state);
  }

  setErrors(...arg: Parameters<FormContext['setErrors']>): void {
    return this.vForm.setErrors(...arg);
  }

  setFieldError(field: keyof Record<string, any>, message: string | string[] | undefined): void {
    return this.vForm.setFieldError(field, message);
  }

  setFieldTouched(field: keyof Record<string, any>, isTouched: boolean): void {
    return this.vForm.setFieldTouched(field, isTouched);
  }

  setFieldValue<T>(...arg: Parameters<FormContext['setFieldValue']>): void {
    return this.vForm.setFieldValue(...arg);
  }

  setTouched(fields: Partial<Record<keyof Record<string, any>, boolean>>): void {
    return this.vForm.setTouched(fields);
  }

  setValues<T extends string= any>(fields: Partial<Record<T, Record<string, any>[T]>>): void {
    return this.vForm.setValues(fields);
  }

  validate(...opts: Parameters<FormContext['validate']>): Promise<FormValidationResult<Record<string, any>>> {
    return this.vForm.validate(...opts);
  }

  validateField(field: keyof Record<string, any>): Promise<ValidationResult> {
    return this.vForm.validateField(field);
  }
}
