
import AppMixin from '@/mixins/app.mixin';
import {mixins, Options, Prop, Ref} from 'vue-property-decorator';

@Options({
  name: 'DividedLayout'
})
export default class DividedLayout extends mixins(AppMixin) {
  @Ref('footerEl')
  readonly footerEl: HTMLElement = null;

  @Prop({
    type: Boolean,
    default: () => true
  })
  readonly floatSidebar!: boolean;

  @Prop({
    type: String,
    default: () => 'Oldalsáv'
  })
  readonly sidebarName!: string;

  isMounted = false;
  showSidebar = false;

  mounted() {
    this.isMounted = true;
  }
}
