
import {Emit, mixins, Model, Options, Prop, Watch} from 'vue-property-decorator';
import {CalendarMixin} from '@/mixins/calendar.mixin';
import {Day} from '@/ui/components/Calendar/types';
import {Moment} from 'moment';
import {FULLNESS_THRESHOLD, MONTHS_LIMIT} from './constants';
import {DateRange} from '@/types';

@Options({
  name: 'CalendarMonth'
})
export default class CalendarMonth extends mixins(CalendarMixin) {
  @Model('modelValue', {type: Object, default: () => ({from: null, to: null})})
  weekRange!: DateRange;

  @Prop({type: Date, required: true})
  currentDate!: Date;

  @Emit('selectDay')
  emitSelectDay(date: Date): void {}

  @Emit('periodChange')
  emitPeriodChange(from: Date, to: Date): void {}

  @Watch('currentDate', {immediate: true})
  onCurrentDateChange(): void {
    this.setCurrentYearMonth(this.startOfMonth, this.endOfMonth);
    this.weekRange.from = this.startOfMonth.toDate();
    this.weekRange.to = this.endOfMonth.toDate();
    this.setDays();
    this.setTimesAtDays();
    this.emitPeriodChange(this.startOfMonth.toDate(), this.endOfMonth.toDate())
  }

  get startOfMonth(): Moment {
    return this.moment(this.currentDate).clone().startOf('month');
  }

  get endOfMonth(): Moment {
    return this.moment(this.currentDate).clone().endOf('month');
  }

  created() {
    if (!this.weekRange.from) {
      this.weekRange.from = this.startOfMonth.toDate();
      this.weekRange.to = this.endOfMonth.toDate();
    }
  }

  isCurrentDay(date: Date): boolean {
    return this.moment(date).isSame(new Date(), 'day');
  }

  getFullnessMarkBgClass(count: number): string {
    if (!count) {
      return 'bg-danger';
    }

    if (count > FULLNESS_THRESHOLD) {
      return 'bg-success';
    } else {
      return 'bg-warning';
    }
  }

  private getMonthDays(): Day[] {
    const days: Day[] = [];
    const startWeek = this.startOfMonth.week();
    const endWeek = this.endOfMonth.week();
    const diff = this.moment.duration(this.endOfMonth.diff(this.startOfMonth));

    for (let week = startWeek; week <= (endWeek === 1 ? (startWeek + diff.weeks() + 1) : endWeek); week++) {
      Array(this.weekDayNumber)
        .fill(0)
        .map((n, i) => this.moment(this.currentDate).week(week).startOf('week').clone().add(n + i, 'day'))
        .forEach(momentDate => {
          const disabled = momentDate.isBefore(this.startOfMonth, 'day') ||
            momentDate.isAfter(this.moment().add(MONTHS_LIMIT, 'months').endOf('month'), 'day') ||
            momentDate.isAfter(this.endOfMonth, 'day');

          days.push({
            date: momentDate.toDate(),
            title: momentDate.format('D'),
            subtitle: null,
            disabled,
            isPast: !disabled && momentDate.isSameOrBefore(new Date(), 'day')
          })
        })
    }

    return days;
  }

  private setDays(): void {
    this.days = this.getMonthDays();
  }
}
