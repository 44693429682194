import {AxiosInstance} from 'axios'
import {AuthApi} from '@/api/modules/auth.api'
import {UserApi} from '@/api/modules/user.api'
import {MeApi} from '@/api/modules/me.api';
import {DokirexApi} from '@/api/modules/dokirex.api';
import {LimitationApi} from '@/api/modules/limitation.api';
import {RecaptchaApi} from '@/api/modules/recaptcha.api';

export type ApiModules = {
  auth: AuthApi,
  user: UserApi,
  me: MeApi,
  dokirex: DokirexApi,
  limitation: LimitationApi,
  recaptcha: RecaptchaApi,
}

export function createApiModules(axios: AxiosInstance): ApiModules {
  return {
    auth: new AuthApi(axios, '/auth'),
    user: new UserApi(axios, '/user'),
    me: new MeApi(axios, '/me'),
    dokirex: new DokirexApi(axios, '/dokirex'),
    limitation: new LimitationApi(axios, '/limitation/schedule'),
    recaptcha: new RecaptchaApi(axios, '/recaptcha')
  }
}
