
import {Emit, mixins, Options, Prop, Ref} from 'vue-property-decorator';
import {InputMixin} from '@/mixins/input.mixin';
import FieldMessages from '@/ui/components/Form/FieldMessages.vue';

@Options({
  name: 'TextInput',
  components: {FieldMessages}
})
export default class TextInput extends mixins(InputMixin) {
  @Prop({type: Boolean, default: () => false})
  asNumber!: boolean;

  @Prop({type: String, default: () => null})
  mask!: string;

  @Ref('inputEl')
  inputElement!: HTMLInputElement;

  @Emit('focusout')
  emitFocusout(event): void {
  }

  onUpKeydown(event: KeyboardEvent): void {
    if (!this.asNumber) {
      return;
    }

    this.incDecValue(true);
  }

  onDownKeydown(event: KeyboardEvent): void {
    if (!this.asNumber) {
      return;
    }

    this.incDecValue(false);
  }

  getNumberMask(): string {
    return this.asNumber
      ? this.mask ?? '#*,####'
      : this.mask;
  }

  private incDecValue(isIncrement: boolean): void {
    const step = 0.0001;
    let value: any = this.value as string ?? '0,0000';
    value = (value as string).replaceAll(',', '.');
    value = Number(value);
    value = isIncrement
      ? value + step
      : value - step;
    this.value = (value < 0 ? 0 : value.toFixed(4)).toString();
  }
}
