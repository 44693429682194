import { renderSlot as _renderSlot, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, {
    ref: "vForm",
    as: "div"
  }, {
    default: _withCtx(({handleSubmit}) => [
      _createElementVNode("form", {
        class: _normalizeClass(_ctx.$attrs.class),
        autocomplete: "off",
        onSubmit: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.emitSubmit()), ["prevent","stop"]))
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 34 /* CLASS, HYDRATE_EVENTS */)
    ]),
    _: 3 /* FORWARDED */
  }, 512 /* NEED_PATCH */))
}