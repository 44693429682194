
import AppMixin from '@/mixins/app.mixin';
import {mixins, Options, Prop, Provide} from 'vue-property-decorator';
import CollapseList from '@/ui/components/CollapseList.vue';

@Options({
  name: 'CollapseContainer'
})
export default class CollapseContainer extends mixins(AppMixin) {
  @Prop({type: Boolean, default: () => false})
  multiple!: boolean;

  @Provide()
  get collapseContainer(): this {
    return this;
  }

  collapses: InstanceType<typeof CollapseList>[] = [];
}
