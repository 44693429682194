import {ModalMixin} from '@/mixins/modal.mixin';
import {mixins, Prop, Ref} from 'vue-property-decorator';
import {FormContext} from 'vee-validate';
import {ApiModules} from '@/api/modules';
import {BaseApi} from '@/api/modules/base.api';
import {BaseEntityModel} from '@/models/base-entity.model';
import BaseResponse from '@/api/response/base.response';

export class CuModalMixin<T extends BaseEntityModel> extends mixins(ModalMixin) {
  @Ref('vForm')
  vForm!: FormContext;

  @Prop({type: Object})
  item!: T;

  @Prop({type: Object})
  defaultData!: T;

  @Prop({type: Boolean, default: () => false})
  readonly!: boolean;

  itemFormData: any;

  created() {
    if (this.item) {
      this.itemFormData = this.parseFormDataFromItem(this.itemFormData, this.item);
    } else if (this.defaultData) {
      this.itemFormData = this.parseFormDataFromItem(this.itemFormData, this.defaultData);
    }
  }

  onFormSubmit(apiModule: keyof ApiModules) {
    this.vForm.validate()
      .then(({valid}) => {
        if (valid) {
          this.loading = true;
          this.item
            ? this.updateItem(apiModule)
              .then(r => {
                this.modal.close();
                this.onSubmit(r?.data);
              })
              .catch(e => this.setFormErrors(this.vForm, e))
              .finally(() => this.loading = false)
            : this.createItem(apiModule)
              .then(r => {
                this.modal.close();
                this.onSubmit(r?.data);
              })
              .catch(e => this.setFormErrors(this.vForm, e))
              .finally(() => this.loading = false)
        }
      });
  }

  createItem(apiModule: keyof ApiModules): Promise<BaseResponse<any>> {
    return (this.$api[apiModule] as BaseApi).create(
      this.getRequestData(this.itemFormData)
    );
  }

  updateItem(apiModule: keyof ApiModules): Promise<BaseResponse<any>> {
    return (this.$api[apiModule] as BaseApi).update(
      this.item.id,
      this.getRequestData(this.itemFormData, this.item)
    );
  }
}
