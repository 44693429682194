import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5887737b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "not-found-main d-flex flex-column align-items-center justify-content-center" }
const _hoisted_2 = { class: "not-found-404" }
const _hoisted_3 = { class: "d-flex gap-2 mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('page.not_found')), 1 /* TEXT */),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t('auth.label_not_found', { path: _ctx.path })), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        class: "btn btn-primary",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
      }, _toDisplayString(_ctx.$t('common.btn_back')), 1 /* TEXT */)
    ])
  ]))
}