
import {ModalMixin} from "@/mixins/modal.mixin";
import {mixins, Options, Prop, Ref, Watch} from "vue-property-decorator";
import {DoctorListItemModel} from "@/models/doctor-list-item.model";
import Modal from "@/ui/components/Modal/Modal.vue";
import Button from "@/ui/components/Button.vue";
import {InterventionBlockModel} from "@/models/intervention-block.model";
import {SelectOption} from "@/ui/components/Form/Select/select.types";
import {InterventionBlockStoreRequest} from "@/api/request/intervention-block-store.request";
import {InterventionBlockUpdateRequest} from "@/api/request/intervention-block-update.request";
import SelectInput from "@/ui/components/Form/Select/SelectInput.vue";
import {FormContext} from "vee-validate";

type InterventionItem = {
  specialist_clinic_name: string;
  specialist_clinic_id: number;
  intervention_name: string;
  intervention_id: number;
};

@Options({
  name: 'InterventionBlockModal',
  components: {SelectInput, Button, Modal}
})
export default class InterventionBlockModal extends mixins(ModalMixin) {
  @Ref('vForm')
  vForm!: FormContext;

  @Prop({
    type: Object as () => DoctorListItemModel,
    required: true
  })
  readonly doctorListItem!: DoctorListItemModel;

  @Prop({
    type: Object as () => InterventionBlockModel,
    required: false,
    default: () => null
  })
  readonly interventionBlock!: InterventionBlockModel;

  @Watch('blockedDoctorId')
  onBlockedDoctorIdChange(): void {
    this.formData.blocked_intervention_id = null;
    const doctor = this.allDoctors.find(item => item.doctor_id === this.blockedDoctorId);
    this.blockedInterventionSelectOptions = doctor ? this.getInterventionSelectOptions(doctor) : [];
  }

  blockerInterventionSelectOptions: SelectOption<InterventionItem>[] = [];
  blockedInterventionSelectOptions: SelectOption<InterventionItem>[] = [];
  blockedDoctorSelectOptions: SelectOption[] = [];
  formData: InterventionBlockStoreRequest | InterventionBlockUpdateRequest = {
    blocker_intervention_id: null,
    blocked_intervention_id: null
  };
  blockedDoctorId: number = null;
  allDoctors: DoctorListItemModel[] = [];

  created() {
    this.setBlockerInterventions();
    this.getAllDoctors().then(() => {
      if (this.interventionBlock) {
        this.initFormData();
      }
    })
  }

  async onFormSubmit(): Promise<void> {
    const {valid} = await this.vForm.validate();

    if (valid) {
      try {
        this.loading = true;

        if (this.interventionBlock) {
          await this.updateItem();
        } else {
          await this.createItem();
        }

        this.onSubmit();
        this.modal.close();
      } finally {
        this.loading = false;
      }
    }
  }

  setBlockerInterventions(): void {
    this.blockerInterventionSelectOptions = this.getInterventionSelectOptions(this.doctorListItem);
  }

  initFormData(): void {
    const blockedDoctor = this.allDoctors.find(doctor => doctor.doctor_id === this.interventionBlock.blocked_doctor_id);
    this.blockedDoctorId = blockedDoctor ? blockedDoctor.doctor_id : null;

    setTimeout(() => {
      const hasBlockerIntervention = !!this.blockerInterventionSelectOptions.find(
        option => option.value === this.interventionBlock.blocker_intervention_id
      );
      const hasBlockedIntervention = !!this.blockedInterventionSelectOptions.find(
        option => option.value === this.interventionBlock.blocked_intervention_id
      );

      if (hasBlockerIntervention) {
        this.formData.blocker_intervention_id = this.interventionBlock.blocker_intervention_id;
      }

      if (this.blockedDoctorId && hasBlockedIntervention) {
        this.formData.blocked_intervention_id = this.interventionBlock.blocked_intervention_id;
      }
    }, 0)
  }

  async getAllDoctors(): Promise<void> {
    try {
      this.loading = true;

      const allDoctor = (await this.$api.dokirex.getAllDoctor())?.data || [];
      this.blockedDoctorSelectOptions = allDoctor.map(doctor => ({label: doctor.doctor_name, value: doctor.doctor_id}));
      this.allDoctors = allDoctor;
    } finally {
      this.loading = false;
    }
  }

  getInterventionSelectOptions(doctorListItem: DoctorListItemModel): SelectOption<InterventionItem>[] {
    const result: SelectOption<InterventionItem>[] = [];

    doctorListItem.specialist_clinics.forEach(clinic => {
      clinic.interventions.forEach(intervention => {
        result.push({
          label: intervention.intervention_name,
          value: intervention.intervention_id,
          item: {
            intervention_id: intervention.intervention_id,
            intervention_name: intervention.intervention_name,
            specialist_clinic_id: clinic.specialist_clinic_id,
            specialist_clinic_name: clinic.specialist_clinic_name
          }
        })
      })
    })

    return result;
  }

  toInterventionSelectOptions(option: SelectOption<InterventionItem>) {
    return option;
  }

  private createItem() {
    return this.$api.limitation.storeInterventionBlock(this.formData);
  }

  private updateItem() {
    return this.$api.limitation.updateInterventionBlock(
      this.interventionBlock.id,
      this.formData
    );
  }
}
