
import AppMixin from '@/mixins/app.mixin';
import {Emit, mixins, Model, Options, Prop} from 'vue-property-decorator';

@Options({
  name: 'HamburgerButton'
})
export default class HamburgerButton extends mixins(AppMixin) {
  @Model('modelValue', {type: Boolean})
  opened!: boolean;
}
