
import AppMixin from '@/mixins/app.mixin';
import {Emit, mixins, Model, Options, Prop, Watch} from 'vue-property-decorator';
import SelectInput from '@/ui/components/Form/Select/SelectInput.vue';
import {SelectOption} from '@/ui/components/Form/Select/select.types';
import {OrderByPropertyNames} from '@/types';
import {getOrderOptionValue} from '@/utils/form.util';
import {OrderByDirection} from '@/api/request/base.request';

@Options({
  name: 'OrderBySelect',
  components: {SelectInput}
})
export default class OrderBySelect extends mixins(AppMixin) {
  @Model('modelValue', {default: () => null})
  value!: string;

  @Prop({
    type: Object as () => OrderByPropertyNames<{}>,
    default: () => ({})
  })
  readonly orderByPropertyNames!: OrderByPropertyNames<{}>;

  @Emit('selectionChange')
  emitSelectionChange(option: SelectOption): void {
  }

  @Watch('orderByPropertyNames', {immediate: true, deep: true})
  onOrderByPropertyNamesChange(): void {
    this.setOptions();
  }

  readonly ascLabel: string = '{field} szerint előlről';
  readonly descLabel: string = '{field} szerint hátulról';

  options: SelectOption[] = [];

  setOptions(): void {
    this.options = [];

    for (const propName in this.orderByPropertyNames) {
      const label = this.orderByPropertyNames[propName];
      this.options.push({
        label: this.ascLabel.replace('{field}', label),
        value: getOrderOptionValue(propName, OrderByDirection.asc)
      })
      this.options.push({
        label: this.descLabel.replace('{field}', label),
        value: getOrderOptionValue(propName, OrderByDirection.desc)
      })
    }
  }
}
