
import {Emit, mixins, Options, Prop} from 'vue-property-decorator';
import {InputMixin} from '@/mixins/input.mixin';
import FieldMessages from '@/ui/components/Form/FieldMessages.vue';

@Options({
  name: 'NumberInput',
  components: {FieldMessages}
})
export default class NumberInput extends mixins(InputMixin) {
  @Prop({type: Number, default: () => null})
  min!: number;

  @Prop({type: Number, default: () => null})
  max!: number;

  @Prop({type: String, default: () => null})
  step!: number;

  @Emit('focusout')
  emitFocusout(event): void {}

  @Emit('keydown')
  emitKeyDown(event): void {}
}
