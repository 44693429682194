import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b4fc0172"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-group" }
const _hoisted_2 = {
  key: 0,
  class: "input-group-text"
}
const _hoisted_3 = { class: "form-check" }
const _hoisted_4 = ["id", "placeholder", "disabled", "readonly", "checked"]
const _hoisted_5 = ["for"]
const _hoisted_6 = {
  key: 1,
  class: "input-group-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_field_messages = _resolveComponent("field-messages")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createBlock(_component_Field, {
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value) = $event)),
    name: _ctx.name || _ctx.uuid,
    label: _ctx.getFieldLabel(),
    rules: _ctx.validationRules
  }, {
    default: _withCtx(({errors, field}) => [
      _createElementVNode("fieldset", _mergeProps({
        class: ["form-field form-field--check", [..._ctx.getFormFieldClassNames(errors), ..._ctx.classNames]]
      }, _ctx.$attrs), [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.$slots.prefix)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                _renderSlot(_ctx.$slots, "prefix", {}, undefined, true)
              ]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_3, [
            _withDirectives(_createElementVNode("input", _mergeProps({
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event))
            }, field, {
              id: _ctx.uuid,
              placeholder: _ctx.placeholder,
              disabled: _ctx.disabled,
              readonly: _ctx.readonly,
              checked: _ctx.computedValue,
              autocomplete: false,
              class: ['form-check-input', ...(errors.length ? ['is-invalid'] : [])].join(' '),
              type: "checkbox",
              onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.emitChange(_ctx.computedValue)))
            }), null, 16 /* FULL_PROPS */, _hoisted_4), [
              [_vModelCheckbox, _ctx.value]
            ]),
            (_ctx.label || _ctx.hasSlotContent('default'))
              ? (_openBlock(), _createElementBlock("label", {
                  key: 0,
                  class: "form-check-label",
                  for: _ctx.uuid
                }, [
                  (!_ctx.hasSlotContent('default'))
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(_ctx.getLabel()), 1 /* TEXT */)
                      ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
                    : _createCommentVNode("v-if", true),
                  _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                ], 8 /* PROPS */, _hoisted_5))
              : _createCommentVNode("v-if", true)
          ]),
          _renderSlot(_ctx.$slots, "buttonSuffix", {}, undefined, true),
          (_ctx.$slots.suffix)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                _renderSlot(_ctx.$slots, "suffix", {}, undefined, true)
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _createVNode(_component_field_messages, {
          "info-message": _ctx.infoMessage,
          errors: errors
        }, null, 8 /* PROPS */, ["info-message", "errors"])
      ], 16 /* FULL_PROPS */)
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["modelValue", "name", "label", "rules"]))
}