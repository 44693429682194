
import {Modal as BootstrapModal} from 'bootstrap'
import {removeElement} from '@/utils/dom.util'
import AppMixin from '@/mixins/app.mixin';
import {mixins, Options, Prop, Ref} from 'vue-property-decorator';
import {generateUUID} from '@/utils/site.util';

@Options({
  name: 'Modal'
})
export default class Modal extends mixins(AppMixin) {
  @Prop({type: Boolean, default: () => true})
  showCloseButton!: boolean;

  @Prop({type: Boolean, default: () => false})
  loading!: boolean;

  @Prop({type: Boolean, default: () => true})
  bodyContain!: boolean;

  @Prop({type: Object, default: () => ({focus: false, backdrop: false} as BootstrapModal.Options)})
  modalOptions!: BootstrapModal.Options;

  @Prop({
    type: Function, default: () => () => {
    }
  })
  onOpen!: () => {};

  @Prop({
    type: Function, default: () => () => {
    }
  })
  onClose!: () => {};

  @Ref('modal')
  modalEl!: HTMLElement;

  @Prop({
    type: String as () => 'md' | 'lg' | 'xl',
    default: () => 'md'
  })
  size!: 'md' | 'lg' | 'xl'

  uuid = generateUUID();
  bsModal: BootstrapModal;
  displayBackdrop = true;
  showBackdrop = false;

  mounted() {
    this.showBackdrop = true;
    this.bsModal = new BootstrapModal(this.modalEl, this.modalOptions);

    const el = this.bsModal['_element'] as Element
    const that = this;

    el.addEventListener('shown.bs.modal', function (event) {
      that.onOpen();
    })

    el.addEventListener('hidden.bs.modal', function (event) {
      that.onClose();
      that.showBackdrop = false;
      setTimeout(() => that.displayBackdrop = false, 150);
      that.$modals.removeInstance(that);
      removeElement(el)
    })

    this.bsModal.show();
    this.$modals.addInstance(this);
  }

  show(): void {
    this.bsModal.show();
  }

  close(): void {
    this.bsModal.hide();
  }

  hasSlotContent(slotName: string): boolean {
    return !!this.$slots[slotName];
  }
}
