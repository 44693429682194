import {RouteLocationNormalized} from "vue-router";
import ROUTES from "@/router/routes";
import {useStore} from '@/store';
import {AppActions} from '@/store/types';

const store = useStore();

export const appGuard = async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  const userIsLogged = store.getters.userLoggedIn;
  const checkLoggedUserRole = (roles) => store.getters.userInRole(roles)

  if (!store.state.user) {
    await store.dispatch(AppActions.REFRESH_LOGGED_USER);
  }

  if (userIsLogged) {
    if ([ROUTES.LOGIN, ROUTES.REGISTER, ROUTES.REGISTER_RESULT, ROUTES.PASSWORD_RESET, ROUTES.PASSWORD_RESET_REQUEST].includes(to.name as string)) {
      return {
        name: ROUTES.DASHBOARD
      };
    }

    if (to.meta.auth_roles && !checkLoggedUserRole(to.meta.auth_roles)) {
      return {
        name: ROUTES.NO_PERMISSION,
        query: {
          redirect: from.fullPath ? from.fullPath : `/${ROUTES.DASHBOARD}`
        }
      }
    }
  }

  if (to.meta.auth_required && !userIsLogged) {
    return {
      name: ROUTES.LOGIN,
    }
  }
}
