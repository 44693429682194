
import {mixins, Options, Prop, Ref} from 'vue-property-decorator';
import {InputMixin} from '@/mixins/input.mixin';
import FieldMessages from '@/ui/components/Form/FieldMessages.vue';

@Options({
  name: 'PasswordInput',
  components: {FieldMessages}
})
export default class PasswordInput extends mixins(InputMixin) {
  @Ref('inputElement')
  inputElement!: HTMLInputElement;

  @Prop({type: Boolean, default: () => true})
  showPasswordVisibilityBtn!: boolean;

  inputType = 'password';

  typeIsText(): boolean {
    return this.inputType === 'text';
  }

  togglePasswordVisibility(forceType?: string): void {
    this.inputType = forceType
      ? forceType
      : this.typeIsText()
        ? 'password'
        : 'text';
    this.inputElement.setAttribute(
      'type',
      this.inputType
    );
  }
}
