import BaseResponse from '@/api/response/base.response'
import {BaseApi} from '@/api/modules/base.api'
import {AuthLoginRequest} from '@/api/request/auth-login.request';
import {useModals} from '@/plugins/modals';
import {UserModel} from '@/models/user.model';
import {useStore} from '@/store';
import {AppActions} from '@/store/types';

export class AuthApi extends BaseApi {
  csrf() {
    return this.axios.get('/csrf-cookie')
  }

  login(data: AuthLoginRequest): Promise<BaseResponse<UserModel>> {
    return this.axios.post(`${this.resource}/login`, data)
  }

  logout(): Promise<BaseResponse> {
    const store = useStore();

    return this.axios.post(`${this.resource}/logout`)
      .then((r) => {
        const modals = useModals();
        store.dispatch(AppActions.INVALIDATE_LOGGED_USER);
        modals.closeAll();
        return Promise.resolve(r as any);
      })
  }
}
