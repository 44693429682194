import {Moment} from 'moment';

export function getCurrentYearMonthStr(
  periodStartMoment: Moment,
  periodEndMoment: Moment,
  showDays: boolean = false
) {
  const startYear = periodStartMoment.format('YYYY');
  const endYear = periodEndMoment.format('YYYY');
  const startMonth = periodStartMoment.format('MM');
  const endMonth = periodEndMoment.format('MM');
  const monthFormat = showDays ? 'MMMM DD.' : 'MMMM';

  const yearStr = startYear === endYear
    ? `${startYear}`
    : `${startYear} - ${endYear}`;
  const monthStr = startMonth === endMonth && !showDays
    ? `${periodStartMoment.format(monthFormat)}`
    : `${periodStartMoment.format(monthFormat)} - ${periodEndMoment.format(monthFormat)}`;

  return {
    yearStr,
    monthStr
  };
}
