import AppMixin from '@/mixins/app.mixin';
import {mixins, Prop, Ref} from 'vue-property-decorator';
import Modal from '@/ui/components/Modal/Modal.vue';
import {getRequestData} from '@/utils/api.util';

export class ModalMixin extends mixins(AppMixin) {
  @Ref('modal')
  modal!: InstanceType<typeof Modal>;

  @Prop({type: Function, default: () => () => {}})
  onSubmit!: (data?: any) => void;

  @Prop({type: Function, default: () => () => {}})
  onClose!: () => void;

  loading = false;

  getRequestData<T extends object, D extends object>(formData: D, item?: T, nullable = false, clearSameData = true): D {
    return getRequestData(formData, item, nullable, clearSameData);
  }
}
