import {AbstractApi} from '@/api/modules/abstract.api';
import BaseResponse from '@/api/response/base.response';
import {SpecialistClinicModel} from '@/models/specialist-clinic.model';
import {DoctorModel} from '@/models/doctor.model';
import {InterventionModel} from '@/models/intervention.model';
import {DateRequest} from '@/api/request/date.request';
import {NationalityModel} from '@/models/nationality.model';
import {BookReservationRequest} from '@/api/request/book-reservation.request';
import {UpdateReservationRequest} from '@/api/request/update-reservation.request';
import {UpdateReservationCommentRequest} from '@/api/request/update-reservation-comment.request';
import {DeleteReservationRequest} from '@/api/request/delete-reservation.request';
import {FreeTimeSegmentModel} from '@/models/free-time-segment.model';
import {MyBookedDateModel} from '@/models/my-booked-date.model';
import {MONTHS_LIMIT} from '@/ui/components/Calendar/constants';
import {appMoment} from '@/utils/moment.util';
import {DoctorListItemModel} from '@/models/doctor-list-item.model';
import {FreeTimeSegmentResponse} from '@/api/response/free-time-segment.response';
import {BaseRequest, OrderByDirection} from '@/api/request/base.request';
import {useStore} from '@/store';

export class DokirexApi extends AbstractApi {
  getSpecialistClinics(): Promise<BaseResponse<SpecialistClinicModel[]>> {
    return this.axios.get(`${this.resource}/specialist-clinics`);
  }

  getDoctors(specialistClinicId: number): Promise<BaseResponse<DoctorModel[]>> {
    return this.axios.get(`${this.resource}/doctors`, {params: {specialist_clinic_id: specialistClinicId}});
  }

  async getAllDoctor(params: any = {}): Promise<BaseResponse<DoctorListItemModel[]>> {
    params = {
      ...params,
      orderBy: 'name',
      orderDirection: 'asc'
    };

    delete params.page;
    delete params.perPage;

    if (!params.search) {
      delete params.search;
    }

    const response: BaseResponse<DoctorListItemModel[]> = await this.axios.get(`${this.resource}/all-doctors`, {params});

    response.data = response.data.map(item => {
      if (Array.isArray(item.specialist_clinics)) {
        return item;
      }

      return {
        ...item,
        specialist_clinics: [item.specialist_clinics]
      }
    })

    return response;
  }

  getInterventions(userSpecialistClinicId: number): Promise<BaseResponse<InterventionModel[]>> {
    return this.axios.get(`${this.resource}/interventions`, {params: {user_specialist_clinic_id: userSpecialistClinicId}});
  }

  async getMyBookedDates(params?: {order_by?: string, order_type?: OrderByDirection}): Promise<BaseResponse<MyBookedDateModel[]>> {
    const response = await this.axios.get(`${this.resource}/my-booked-dates`, {params: params ?? {}}) as BaseResponse<MyBookedDateModel[]>;
    response.data = response.data.filter(item => appMoment(item.from).isSameOrAfter());
    useStore().state.myBookedDates = response.data ?? [];
    return response;
  }

  async getFreeTimeSegments(params: DateRequest & {modified_date?: string}): Promise<BaseResponse<FreeTimeSegmentModel[]>> {
    params = {
      from: appMoment(new Date()).format('YYYY-MM-DD'),
      to: appMoment(new Date())
        .add(MONTHS_LIMIT + 1, 'months')
        .startOf('month')
        .format('YYYY-MM-DD'),
      ...params
    }
    const response = (await this.axios.get(`${this.resource}/free-time-segments`, {params})) as BaseResponse<FreeTimeSegmentResponse[]>
    const freeTimeSegments: FreeTimeSegmentModel[] = [];

    response.data.forEach(item => {
      const index = freeTimeSegments.findIndex(segment => segment.day_id === item.day_id);
      if (index > -1) {
        freeTimeSegments[index].free_intervals.push({from: item.from, to: null})
      } else {
        freeTimeSegments.push({
          day: item.day,
          day_id: item.day_id,
          free_intervals: [{from: item.from, to: null}]
        })
      }
    })

    return {
      ...response,
      data: freeTimeSegments
    }
  }

  getNationalities(): Promise<BaseResponse<NationalityModel[]>> {
    return this.axios.get(`${this.resource}/nationalities`);
  }

  bookReservation(data: BookReservationRequest) { // todo: set response type
    return this.axios.post(`${this.resource}/reservation`, data);
  }

  updateReservation(data: UpdateReservationRequest) { // todo: set response type
    return this.axios.put(`${this.resource}/reservation`, data);
  }

  updateReservationComment(data: UpdateReservationCommentRequest) { // todo: set response type
    return this.axios.put(`${this.resource}/reservation-comment`, data);
  }

  deleteReservation(params: DeleteReservationRequest) {
    return this.axios.delete(`${this.resource}/reservation`, {params});
  }
}
