import {Vue} from 'vue-property-decorator';
import {UserRole} from '@/enums/user.enum';
import {FormContext} from 'vee-validate';
import {AxiosError} from 'axios';
import {mask, tokens} from 'maska';

export default class AppMixin extends Vue {
  readonly userRoles = UserRole;
  readonly dateMask = '####.##.##';
  readonly dateInputFormat = 'YYYY.MM.DD';
  readonly phoneMask = {
    mask: 'Z*', tokens: { 'Z': { pattern: /[0-9+-\/]/ }}
  };
  readonly tajMask = '### ### ###';
  readonly hourMinuteMask = '##:##';

  getSocialSecurityNumberMask(value: string): string {
    if ((value ?? '').replaceAll(' ', '').length > 9) {
      return '#*';
    }

    return this.tajMask;
  }

  formatUserRole(value: UserRole): string {
    return this.$t(`user.role_${value}`);
  }

  booleanActiveInactive(value: boolean): string {
    return value ? 'Aktív' : 'Inaktív';
  }

  setFormErrors(vForm: FormContext, e: AxiosError): void {
    vForm.setErrors(e?.response?.data?.data || {});
  }

  clearHtml(html: string): string {
    const temp = document.createElement('div');
    temp.innerHTML = html.replaceAll('</p>', '&nbsp;').replaceAll('<p>', '');
    const result = temp.innerText;
    temp.remove();
    return result;
  }

  hasSlotContent(slotName: string): boolean {
    return !!this.$slots[slotName];
  }

  parseFormDataFromItem<T extends object, D extends object>(formData: D, item: T): D {
    Object.keys(formData)
      .forEach(key => {
        if (item.hasOwnProperty(key)) {
          formData[key] = item[key];
        }
      });

    return formData;
  }

  dateFormat(value: string, useUtc = false): string {
    return this._dateFormat(value, 'YYYY-MM-DD', useUtc);
  }

  dateTimeFormat(value: string, useUtc = false): string {
    return this._dateFormat(value, 'YYYY-MM-DD HH:mm:ss', useUtc);
  }

  private _dateFormat(value: string, format: string, useUtc = false): string {
    if (this.moment(value).isValid()) {
      if (useUtc) {
        return this.moment(value).utc().format(format);
      }

      return this.moment(value).format(format);
    }

    return value;
  }
}
