
import {mixins, Options} from 'vue-property-decorator';
import {InputMixin} from '@/mixins/input.mixin';
import FieldMessages from '@/ui/components/Form/FieldMessages.vue';

@Options({
  name: 'DateInput',
  components: {FieldMessages}
})
export default class DateInput extends mixins(InputMixin) {
  masks = {
    input: 'YYYY-MM-DD',
  };
}
