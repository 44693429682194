import {SelectOption} from '@/ui/components/Form/Select/select.types';
import {useI18n} from 'vue-i18n';
import {UserRole} from '@/enums/user.enum';
import {BaseRequest, OrderByDirections} from '@/api/request/base.request';

export function getUserRoleSelectOptions(): SelectOption[] {
  const {t} = useI18n();
  return Object.values(UserRole)
    .map(role => ({value: role, label: t(`user.role_${role}`)}));
}

export function getOrderOptionValue(propName: string, order: OrderByDirections): string {
  return `${propName}:${order}`;
}

export function parseOrderOptionValue(value: string): Pick<BaseRequest, 'orderBy' | 'orderDirection'> {
  const [propName, direction] = value.split(':');
  return {
    orderBy: propName,
    orderDirection: direction as OrderByDirections
  };
}

export function isHungarian(options: Array<SelectOption>, value: unknown): boolean {
  const option = options.find(item => item.value === value);

  if (option) {
    return option.label === 'magyar';
  }

  return false;
}
