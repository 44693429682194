import {BaseApi} from '@/api/modules/base.api'
import {UserModel} from '@/models/user.model'
import {ResetPasswordRequest} from '@/api/request/reset-password.request';
import BaseResponse from '@/api/response/base.response';
import {UserActivateRequest} from '@/api/request/user-activate.request';
import {UserRegisterRequest} from '@/api/request/user-register.request';

export class UserApi extends BaseApi<UserModel> {
  resetPassword(data: ResetPasswordRequest): Promise<BaseResponse> {
    return this.axios.post(`${this.resource}/reset-password`, data);
  }

  activate(data: UserActivateRequest): Promise<BaseResponse<UserModel>> {
    return this.axios.post(`${this.resource}/activate`, data);
  }

  activationResend(email: string): Promise<BaseResponse> {
    return this.axios.post(`${this.resource}/activation-resend`, {email});
  }

  register(data: UserRegisterRequest): Promise<BaseResponse<UserModel>> {
    return this.axios.post(`${this.resource}/register`, data);
  }
}
