
import {mixins, Options, Prop} from "vue-property-decorator";
import Modal from "@/ui/components/Modal/Modal.vue";
import {ModalMixin} from "@/mixins/modal.mixin";
import Button from "@/ui/components/Button.vue";
import {UserModel} from "@/models/user.model";
import {PatientHasDoctorModel} from "@/models/patient-has-doctor.model";
import ConfirmModal from "@/ui/modals/ConfirmModal.vue";

@Options({
  name: 'PatientHasDoctors',
  components: {Button, Modal}
})
export default class PatientHasDoctors extends mixins(ModalMixin) {
  @Prop({
    type: Object as () => UserModel,
    required: true
  })
  readonly patient!: UserModel;

  loading = false;
  patientHasDoctors: Array<PatientHasDoctorModel> = [];

  created() {
    this.getDoctors();
  }

  async getDoctors(): Promise<void> {
    try {
      this.loading = true;
      this.patientHasDoctors = (await this.$api.limitation.getDoctorLimitations(this.patient.id))?.data ?? [];
    } finally {
      this.loading = false;
    }
  }

  inactivateConnection(item: PatientHasDoctorModel): void {
    this.$modals.show(ConfirmModal, {
      message: 'Biztosan törli az alábbi orvos kapcsolatot?',
      onSubmit: async () => {
        try {
          this.loading = true;
          await this.$api.limitation.inactivateDoctorLimitation(item.user_has_doctor_id);
          await this.getDoctors();
        } finally {
          this.loading = false;
        }
      }
    });
  }
}
