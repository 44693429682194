
import ROUTES from '@/router/routes';
import {AuthLoginRequest} from '@/api/request/auth-login.request';
import {mixins, Options, Ref} from 'vue-property-decorator';
import AppMixin from '@/mixins/app.mixin';
import {FormContext} from 'vee-validate';
import TextInput from '@/ui/components/Form/TextInput.vue';
import PasswordInput from '@/ui/components/Form/PasswordInput.vue';
import Checkbox from '@/ui/components/Form/Checkbox.vue';
import {AppActions} from '@/store/types';
import Button from '@/ui/components/Button.vue';
import DoubleCenterBoxWrapper from '@/ui/components/DoubleCenterBoxWrapper.vue';
import {ScriptInjection} from "@/types";
import {createScriptInjection} from "@/utils/dom.util";
import {ANALYTICS_CODE} from "@/constants";

@Options({
  name: 'LoginPage',
  components: {Button, Checkbox, DoubleCenterBoxWrapper, PasswordInput, TextInput}
})
export default class LoginPage extends mixins(AppMixin) {
  @Ref('vForm')
  vForm!: FormContext;

  authData: Partial<AuthLoginRequest> = {};
  routes = ROUTES;
  loading = false;
  scriptInjection: ScriptInjection = null;

  mounted() {
    this.scriptInjection = createScriptInjection(ANALYTICS_CODE, 'analyticsScriptTag');
  }

  beforeUnmount() {
    this.scriptInjection?.remove();
  }

  onFormSubmit(): void {
    this.vForm.validate()
      .then(({valid}) => {
        if (valid) {
          this.login();
        }
      })
  }

  login(): void {
    this.loading = true;
    try {
      this.$api.auth.csrf().then(() => {
        this.$api.auth.login(this.authData as AuthLoginRequest)
          .then(r => {
            const user = r.data;
            this.$store.dispatch(AppActions.SET_LOGGED_USER, user);

            this.redirect();
          })
          .finally(() => this.loading = false)
      })
    } catch (e) {
      this.loading = false;
    }
  }

  private redirect(): void {
    const redirectUri = this.$router.currentRoute.value.query.redirect;
    const fallbackRoute = ROUTES.DASHBOARD;
    this.$router.replace(redirectUri ? redirectUri.toString() : `/${fallbackRoute}`);
  }
}
