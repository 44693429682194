
import ROUTES from '@/router/routes'
import {mixins, Options} from 'vue-property-decorator';
import AppMixin from '@/mixins/app.mixin';

@Options({
  name: 'NoPermissionPage'
})
export default class NoPermissionPage extends mixins(AppMixin) {
  back() {
    const redirectUri = this.$router.currentRoute.value.query.redirect;
    this.$router.replace(redirectUri ? redirectUri.toString() : `/${ROUTES.DASHBOARD}`)
  }

  loginAsAnother() {
    this.$router.replace(`/${ROUTES.LOGIN}`)
  }
}
