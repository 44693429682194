import {UserSex} from '@/enums/user.enum';

export default {
  site: {
    title: 'Endocare Endokrinológiai Központ',
  },

  page: {
    no_permission: '403',
    not_found: '404',
    main: 'Főoldal',
    login: 'Bejelentkezés',
    sign_up: 'Regisztráció',
    sign_up_result: 'Sikeres regisztráció',
    users: 'Felhasználók',
    dashboard: 'Naptár',
    my_times: 'Időpontjaim',
    profile: 'Profilom',
    doctors: 'Megkötések',
    password_reset: 'Jelszó visszaállítás',
    password_reset_request: 'Jelszó visszaállítás',
    limitations: 'Limitek',
    blocks: 'Párhuzamos kivételek',
  },

  page_title: {

  },

  page_description: {

  },

  locale: {
    en: 'Angol',
    hu: 'Magyar',
  },

  validation_message: {
    equal: 'A mező értéke helytelen',
  },

  common: {
    label_locale: 'Nyelv',
    label_yes: 'Igen',
    label_no: 'Nem',
    label_loading: 'Betöltés..',
    label_search: 'Keresés..',
    title_confirm: 'Megerősítés..',
    field_id: '#',
    field_created_at: 'létrehozva',
    field_updated_at: 'Utoljára frissítve',
    field_deleted_at: 'Törölve',
    field_actions: 'Műveletek',
    btn_create: 'Új',
    btn_edit: 'Szerkesztés',
    btn_delete: 'Törlés',
    btn_back: 'Vissza',
    btn_close: 'Bezárás',
    btn_cancel: 'Mégsem',
    btn_save: 'Mentés',
    btn_export: 'Exportálás',
    btn_confirm: 'Megerősítés',
    btn_show: 'Megtekintés',
    btn_details: 'Részletek',
    btn_actions: 'Műveletek',
    btn_filters: 'Szűrők',
    btn_next: 'Tovább',
    btn_expenditure: 'Kiadás',
    btn_add: 'Hozzáad',
    btn_download: 'Letöltés',
    status_active: 'Aktív',
    status_inactive: 'Inaktív',
    msg_no_items: 'Nincs megjeleníthető elem.',
    field_birth_date: 'Születési dátum',
  },

  pagination: {
    label_prev: 'Előző',
    label_next: 'Következő',
    total: 'Összesen {total} bejegyzés'
  },

  auth: {
    label_not_found: 'A keresett oldal "/{path}" nem található.',
    label_no_permissions: 'Nincs jogosultságod a kért oldal betöltéséhez.',
    btn_login: 'Belépés',
    btn_sign_up: 'Regisztráció',
    btn_logout: 'Kijelentkezés',
    btn_login_as_another: 'Bejelentkezés másik felhasználóval',
    role_guest: 'Vendég',
    role_user: 'Felhasználó',
    role_admin: 'Adminisztrátor',
    role_patient: 'Páciens',
    role_super_admin: 'Szuper admin',
  },

  user: {
    title_users: 'Felhasználók',
    title_create: 'Felhasználó létrehozása',
    title_update: 'Felhasználó szerkesztése',
    title_delete: 'Felhasználó törlése',
    field_id: 'Azonosító',
    field_full_name: 'Név',
    field_first_name: 'Keresztnév',
    field_last_name: 'Vezetéknév',
    field_email: 'E-mail cím',
    field_partner: 'Partner',
    field_password: 'Jelszó',
    field_password_confirmation: 'Jelszó megerősítése',
    field_password_old: 'Jelenlegi jelszó',
    field_status: 'Státusz',
    field_role: 'Szerepkör',
    field_license_num: 'Engedélyszám',
    field_health_identifier: 'EÜ. azonosító',
    role_user: 'Gyógyszerész',
    role_admin: 'Admin',
    role_super_admin: 'Super admin',
    btn_create: 'Új felhasználó',
    msg_delete: 'Biztos, hogy törlöd a(z) "{email}" felhasználót?',
    [`sex_${UserSex.MALE}`]: 'férfi',
    [`sex_${UserSex.FEMALE}`]: 'nő',
  },

  doctors: {
    field_doctor: 'orvos',
    field_intervention: 'beavatkozás',
    field_from: 'int.\nkezdete',
    field_to: 'int.\nvége',
    field_interval: 'időpont /<br>intervallum',
    field_day_of_week: 'érvényes',
    field_other: 'maximum fogl. /<br>szigorú szab.',
    field_specialist: 'szakterület',
    field_interventions_count: 'szolgáltatások száma',
    field_service: 'szolgáltatás',
  },

  intervention_block: {
    field_blocker: 'blokkoló',
    field_blocked: 'blokkolt'
  }
}
