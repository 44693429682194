
import {mixins, Options, Ref} from 'vue-property-decorator';
import DividedLayout from '@/ui/layouts/DividedLayout.vue';
import ROUTES from '@/router/routes';
import AppMixin from '@/mixins/app.mixin';
import Button from '@/ui/components/Button.vue';
import TextInput from '@/ui/components/Form/TextInput.vue';
import PasswordInput from '@/ui/components/Form/PasswordInput.vue';
import {ProfileUpdateRequest} from '@/api/request/profile-update.request';
import {UserModel} from '@/models/user.model';
import {FormContext} from 'vee-validate';
import {clearEmptyString, getRequestData} from '@/utils/api.util';
import {getCustomerServiceData} from '@/utils/site.util';
import {PRIVACY_STATEMENT_DOC_PATH} from '@/constants';

@Options({
  name: 'ProfilePage',
  components: {PasswordInput, TextInput, Button, DividedLayout}
})
export default class ProfilePage extends mixins(AppMixin) {
  @Ref('vForm')
  readonly vForm!: FormContext;

  formData: ProfileUpdateRequest = {
    password: null,
    password_new: null,
    password_new_confirmation: null,
    email: null,
    phone: null,
    name: null
  }
  profileData: Partial<UserModel> = {};
  userActionInProgress = false;
  dataLoading = false;
  customerService = getCustomerServiceData();
  readonly privacyPath = PRIVACY_STATEMENT_DOC_PATH;

  created() {
    this.getMe();
  }

  async onFormSubmit() {
    const validationResult = await this.vForm.validate();
    if (validationResult.valid) {
      this.userActionInProgress = true;
      await this.$api.me.updateMe(
        clearEmptyString(getRequestData(this.formData))
      ).finally(() => this.userActionInProgress = false)
    }
  }

  logOut(): void {
    this.$api.auth.logout()
      .then(() => this.$router.replace(ROUTES.LOGIN));
  }

  async getMe() {
    this.dataLoading = true;
    const response = await this.$api.me.getMe().finally(() => this.dataLoading = false);
    this.profileData = response.data;
    this.profileData.is_foreign = !!this.profileData.is_foreign;
    this.formData = this.parseFormDataFromItem(this.formData, response.data);
  }

  getPasswordValidationRules(...rules: string[]): string {
    if (this.formData.password ||
      this.formData.password_new ||
      this.formData.password_new_confirmation) {
      return rules.join('|');
    }

    return null;
  }
}
