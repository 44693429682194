import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "order-by-select" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_select_input, {
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      placeholder: "Rendezés",
      options: _ctx.options,
      clearable: false,
      onSelectionChange: _ctx.emitSelectionChange
    }, null, 8 /* PROPS */, ["modelValue", "options", "onSelectionChange"])
  ]))
}