
import {mixins, Options, Ref} from 'vue-property-decorator';
import AppMixin from '@/mixins/app.mixin';
import CenterBoxWrapper from '@/ui/components/CenterBoxWrapper.vue';
import {FormContext} from 'vee-validate';
import TextInput from '@/ui/components/Form/TextInput.vue';
import PasswordInput from '@/ui/components/Form/PasswordInput.vue';
import DateInput from '@/ui/components/Form/DateInput.vue';
import {UserRegisterRequest} from '@/api/request/user-register.request';
import {appMoment} from '@/utils/moment.util';
import ROUTES from '@/router/routes';
import Button from '@/ui/components/Button.vue';
import {SelectOption} from '@/ui/components/Form/Select/select.types';
import {NationalityModel} from '@/models/nationality.model';
import {PRIVACY_STATEMENT_DOC_PATH, USER_SEX_SELECT_OPTIONS} from '@/constants';
import {AppActions, AppGetters} from '@/store/types';
import SelectInput from '../components/Form/Select/SelectInput.vue';
import Checkbox from '@/ui/components/Form/Checkbox.vue';
import { isHungarian } from 'src/utils/form.util';
import {ScriptInjection} from "@/types";
import {createScriptInjection} from "@/utils/dom.util";

@Options({
  name: 'RegistrationPage',
  components: {Checkbox, SelectInput, Button, DateInput, PasswordInput, TextInput, CenterBoxWrapper}
})
export default class RegistrationPage extends mixins(AppMixin) {
  @Ref('vForm')
  vForm!: FormContext;

  colClasses = 'col-12 col-lg-6';
  routes = ROUTES;
  loading = false;

  tajNumber: string = null;
  itemFormData: UserRegisterRequest = {
    name: null,
    email: null,
    password: null,
    password_confirmation: null,
    born_date: null,
    social_security_number: null,
    phone: null,
    nationality_id: null,
    sex_id: null,
    accepted: null,
    is_foreign: null
  };
  noTajNumber = false;

  nationalitySelectOptions: Array<SelectOption<NationalityModel>> = [];
  userSexSelectOptions: Array<SelectOption> = USER_SEX_SELECT_OPTIONS;

  siteKey: string = null;
  scriptInjection: ScriptInjection = null;

  private readonly captchaScriptTagClass = 'captchaScriptTag';
  readonly privacyPath = PRIVACY_STATEMENT_DOC_PATH;

  created() {
    this.nationalitySelectOptions = this.$store.getters[AppGetters.NATIONALITY_SELECT_OPTIONS]
  }

  mounted() {
    this.loadCaptcha();
  }

  beforeUnmount() {
    document.querySelector('.grecaptcha-badge')?.remove();
    this.scriptInjection?.remove();
  }

  async onFormSubmit(): Promise<void> {
    const {valid} = await this.vForm.validate();

    if (valid) {
      try {
        this.loading = true;
        window.grecaptcha.ready(async () => {
          const token = await window.grecaptcha.execute(this.siteKey, {action: 'submit'});

          if (token) {
            await this.$api.auth.csrf();
            this.$api.user.register({
              ...this.itemFormData,
              social_security_number: !this.noTajNumber ? this.tajNumber : this.itemFormData.social_security_number,
              born_date: this.moment(this.itemFormData.born_date, this.dateInputFormat).format('YYYY-MM-DD'),
              is_foreign: !!this.noTajNumber,
              "g-recaptcha-response": token
            }).then(r => {
              this.$store.dispatch(AppActions.SET_LAST_REGISTERED_EMAIL, this.itemFormData.email)
              this.$router.push({name: ROUTES.REGISTER_RESULT})
            }).catch(e => this.setFormErrors(this.vForm, e))
              .finally(() => this.loading = false)
          }
        })
      } catch (e) {
        this.loading = false;
      }
    }
  }

  onTajCheckboxChange(): void {
    this.itemFormData.social_security_number = null;
    this.tajNumber = null;
    this.vForm.setFieldValue('social_security_number', null);
  }

  async loadCaptcha(): Promise<void> {
    const response = await this.$api.recaptcha.getCaptcha()
    this.siteKey = response.data.siteKey;
    if (response.data?.htmlScriptTags) {
      this.scriptInjection = createScriptInjection(response.data.htmlScriptTags, this.captchaScriptTagClass)
    }
  }
}
