
import {ModalMixin} from '@/mixins/modal.mixin';
import {mixins, Options, Prop, Ref} from 'vue-property-decorator';
import Modal from '@/ui/components/Modal/Modal.vue';
import {FreeTimeSegmentModel} from '@/models/free-time-segment.model';
import Calendar from '../components/Calendar/Calendar.vue';
import {MyBookedDateModel} from '@/models/my-booked-date.model';
import TimeSummary from '@/ui/components/TimeSummary.vue';
import Button from '@/ui/components/Button.vue';
import CustomIcon from '@/ui/components/CustomIcon.vue';
import {AppActions} from "@/store/types";

@Options({
  name: 'CalendarModal',
  components: {CustomIcon, Button, TimeSummary, Calendar, Modal}
})
export default class CalendarModal extends mixins(ModalMixin) {
  @Ref('calendar')
  calendar!: InstanceType<typeof Calendar>;

  @Prop({
    type: Object as () => MyBookedDateModel,
    default: () => null
  })
  bookedDate!: MyBookedDateModel;

  freeTimeSegments: FreeTimeSegmentModel[] = [];
  loading = false;
  freeTimesFetched = false;

  created() {
    this.getFreeTimeSegments();
  }

  getFreeTimeSegments(): void {
    if (!this.bookedDate.interval || !this.bookedDate.reservation_id) {
      return;
    }

    this.loading = true;
    this.$api.dokirex.getFreeTimeSegments({
      intervention_id: this.bookedDate.intervention_id,
      doctor_id: this.bookedDate.doctor_id,
      specialist_clinic_id: this.bookedDate.specialist_clinic_id,
      modified_date: this.moment(this.bookedDate.from).format('YYYY-MM-DD')
    }).then(r => {
      this.freeTimeSegments = r.data;
      this.freeTimesFetched = true;
    }).finally(() => this.loading = false)
  }

  save(): void {
    const selectedTime = this.$store.state.selections.time;
    this.loading = true;
    this.$api.dokirex.updateReservation({
      reservation_id: this.bookedDate.reservation_id,
      user_specialist_clinic_id: this.bookedDate.user_specialist_clinic_id,
      intervention_id: this.bookedDate.intervention_id,
      from: selectedTime.time.from,
      day_id: selectedTime.day_id,
      day: this.moment(selectedTime.date).format('YYYY-MM-DD'),
      comment: 'Időpont foglalás'
    }).then(() => {
      this.onSubmit();
      this.modal.close();
    }).catch(async () => {
      await this.$store.dispatch(AppActions.CLEAR_SELECTIONS);
      this.getFreeTimeSegments();
    }).finally(() => this.loading = false)
  }
}
