
import AppMixin from '@/mixins/app.mixin';
import {mixins, Options, Prop} from 'vue-property-decorator';

@Options({
  name: 'DoubleCenterBoxWrapper'
})
export default class DoubleCenterBoxWrapper extends mixins(AppMixin) {
  @Prop({type: String, default: () => 'md'})
  size!: 'md' | 'lg';
}
