import {Tooltip} from 'bootstrap';
import {DirectiveBinding} from 'vue';
import {Directive} from '@vue/runtime-core';

export function tooltip(el: HTMLElement, binding: DirectiveBinding) {
  const value = binding.value;
  el.title = value;

  if (value && !el['tooltip']) {
    el['tooltip'] = new Tooltip(el, {
      container: 'body', trigger: 'hover', html: true
    }) as any;
  } else {
    el.removeAttribute('title');
  }
}

export function removeTooltip(el: any) {
  el?.tooltip?.dispose();
}

export const TooltipDirective: Directive = {
  mounted: tooltip,
  updated: tooltip,
  beforeUnmount: removeTooltip,
  deep: true
};
