
import {ModalMixin} from '@/mixins/modal.mixin';
import {mixins, Options, Prop, Ref, Watch} from 'vue-property-decorator';
import Modal from '@/ui/components/Modal/Modal.vue';
import {DoctorInterventionLimitStoreRequest} from '@/api/request/doctor-intervention-limit-store.request';
import TextInput from '@/ui/components/Form/TextInput.vue';
import SelectInput from '@/ui/components/Form/Select/SelectInput.vue';
import NumberInput from '@/ui/components/Form/NumberInput.vue';
import {SelectOption} from '@/ui/components/Form/Select/select.types';
import {DoctorListItemModel} from '@/models/doctor-list-item.model';
import {FormContext} from 'vee-validate';
import {DoctorInterventionLimitModel} from '@/models/doctor-intervention-limit.model';
import Checkbox from '@/ui/components/Form/Checkbox.vue';
import Button from '@/ui/components/Button.vue';
import {ALL_DAYS_SELECT_VALUE, ORDER_RULE_CAN_NOT_BOOKED_LABEL, ORDER_RULE_CAN_NOT_BOOKED_VALUE} from '@/constants';
import { getRequestData } from 'src/utils/api.util';

@Options({
  name: 'OrderRuleModal',
  components: {Button, Checkbox, NumberInput, SelectInput, TextInput, Modal}
})
export default class OrderRuleModal extends mixins(ModalMixin) {
  @Ref('vForm')
  vForm!: FormContext;

  @Prop({
    type: Object as () => DoctorInterventionLimitModel,
    default: () => null
  })
  readonly item!: DoctorInterventionLimitModel;

  @Prop({
    type: Boolean,
    default: () => false
  })
  readonly isCopy!: boolean;

  itemFormData: DoctorInterventionLimitStoreRequest = {
    doctor_id: null,
    intervention_id: null,
    from: null,
    to: null,
    day_of_week: ALL_DAYS_SELECT_VALUE,
    maximum: 1,
    strict: false
  };
  doctorSelectOptions: SelectOption<DoctorListItemModel>[] = [];
  interventionSelectOptions: SelectOption[] = [];
  isInterval: boolean = false;

  get daySelectOptions(): SelectOption[] {
    return this.$store.state.dayOptions;
  }

  created() {
    this.initData();
  }

  onIsIntervalChange(): void {
    if (!this.isInterval) {
      this.itemFormData.maximum = 1;
    }
  }

  onDoctorChange(): void {
    this.setInterventionSelectOptions();
    this.itemFormData.intervention_id = null;
  }

  onFormSubmit(): void {
    this.vForm.validate().then(({valid}) => {
      if (valid) {
        this.item && !this.isCopy ? this.updateRule() : this.createRule();
      }
    })
  }

  async initData(): Promise<void> {
    if (this.item) {
      this.isInterval = this.item.from !== this.item.to;
      this.itemFormData = this.parseFormDataFromItem(this.itemFormData, this.item);
      this.itemFormData.strict = !!this.itemFormData.strict;

      await this.getDoctors();
      this.setInterventionSelectOptions();

      if (this.item.day_of_week !== ALL_DAYS_SELECT_VALUE) {
        this.itemFormData.day_of_week = this.daySelectOptions.find(item => item.label === this.item.day_of_week)?.value as any
      }
    } else {
      await this.getDoctors();
      this.setInterventionSelectOptions();
    }
  }

  createRule(): void {
    this.loading = true;
    this.$api.limitation.create(this.getRequestData(this.itemFormData))
      .then(r => {
        this.modal.close();
        this.onSubmit(r?.data);
      })
      .catch(e => this.setFormErrors(this.vForm, e))
      .finally(() => this.loading = false)
  }

  updateRule(): void {
    this.loading = true;
    this.$api.limitation.update(this.item.id, this.getRequestData(this.itemFormData))
      .then(r => {
        this.modal.close();
        this.onSubmit(r?.data);
      })
      .catch(e => this.setFormErrors(this.vForm, e))
      .finally(() => this.loading = false)
  }

  getRequestData<T extends object, D extends object>(formData: D, item?: T, nullable: boolean = false, clearSameData: boolean = true): D {
    const result: DoctorInterventionLimitStoreRequest = getRequestData(formData, item, nullable, clearSameData) as any;

    if (!this.isInterval) {
      result.to = result.from;
    }

    return result as any;
  }

  getDoctors() {
    this.loading = true;
    return this.$api.dokirex.getAllDoctor()
      .then(r => {
        this.doctorSelectOptions = r.data.map(item => ({
          label: item.doctor_name,
          value: item.doctor_id,
          item
        }))
        return Promise.resolve(r);
      })
      .finally(() => this.loading = false)
  }

  setInterventionSelectOptions(): void {
    const doctor = this.doctorSelectOptions.find(item => item.value === this.itemFormData.doctor_id);
    this.interventionSelectOptions = doctor
      ? doctor.item.specialist_clinics.map(item => ({label: item.specialist_clinic_name, options: item.interventions.map(intervention => ({
          label: intervention.intervention_name,
          value: intervention.intervention_id
        }))}))
      : [];
    this.interventionSelectOptions.push({
      label: 'Egyéb',
      options: [
        {label: ORDER_RULE_CAN_NOT_BOOKED_LABEL, value: ORDER_RULE_CAN_NOT_BOOKED_VALUE}
      ]
    })
  }
}
