import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    ref: "modal",
    "on-close": _ctx.onClose
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.title ? _ctx.title : _ctx.$t('common.title_confirm')), 1 /* TEXT */)
    ]),
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.message), 1 /* TEXT */)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        color: "secondary",
        size: "lg",
        outlined: "",
        "data-bs-dismiss": "modal"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('common.btn_close')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_Button, {
        color: "primary",
        size: "lg",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.confirm()))
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('common.btn_confirm')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["on-close"]))
}