import {AbstractApi} from '@/api/modules/abstract.api';
import BaseResponse from '@/api/response/base.response';
import {UserModel} from '@/models/user.model';
import {ProfileUpdateRequest} from '@/api/request/profile-update.request';

export class MeApi extends AbstractApi {
  getMe(): Promise<BaseResponse<UserModel>> {
    return this.axios.get(`${this.resource}`);
  }

  updateMe(data: ProfileUpdateRequest): Promise<BaseResponse<UserModel>> {
    return this.axios.put(`${this.resource}`, data);
  }
}
