
import {mixins, Options, Ref} from 'vue-property-decorator';
import AppMixin from '@/mixins/app.mixin';
import PageWrapper from '@/ui/components/PageWrapper.vue';
import {ListColumn} from '@/ui/components/List/list.types';
import {UserModel} from '@/models/user.model';
import List from '@/ui/components/List/List.vue';
import SelectInput from '@/ui/components/Form/Select/SelectInput.vue';
import TextInput from '@/ui/components/Form/TextInput.vue';
import UserModal from '@/ui/modals/UserModal.vue';
import ConfirmModal from '@/ui/modals/ConfirmModal.vue';
import DividedLayout from '@/ui/layouts/DividedLayout.vue';
import {USER_ROLE_SELECT_OPTIONS} from '@/constants/user-role-select-options.constant';
import {UserRole, UserStatus} from '@/enums/user.enum';
import Button from '@/ui/components/Button.vue';
import OrderBySelect from '@/ui/components/Form/OrderBySelect.vue';
import {OrderByPropertyNames} from '@/types';
import {getOrderOptionValue} from '@/utils/form.util';
import {OrderByDirection} from '@/api/request/base.request';
import CustomIcon from '@/ui/components/CustomIcon.vue';
import PatientHasDoctors from "@/ui/modals/PatientHasDoctors.vue";

@Options({
  name: 'UsersPage',
  components: {CustomIcon, OrderBySelect, Button, DividedLayout, TextInput, SelectInput, List, PageWrapper}
})
export default class UsersPage extends mixins(AppMixin) {
  @Ref('list')
  list!: InstanceType<typeof List>;

  columns: ListColumn<UserModel>[] = [
    {
      field: 'name',
      title: 'user.field_full_name'
    },
    {
      field: 'social_security_number',
      title: 'user.field_health_identifier'
    },
    {
      field: 'born_date',
      title: 'common.field_birth_date'
    },
    {
      field: 'email',
      title: 'user.field_email'
    }
  ];

  userRoleOptions = USER_ROLE_SELECT_OPTIONS;
  filters: Record<string, any> = {};
  filterParams: any = {};
  colClasses = 'col-12 col-md-4 col-xl-3';
  orderByProps: OrderByPropertyNames<UserModel> = {
    name: 'Név',
    email: 'E-mail cím',
    born_date: 'Szül. dátum',
    created_at: 'Létrehozás'
  }
  orderValue: string = getOrderOptionValue('name', OrderByDirection.asc);
  loading = false;

  created() {
    this.initFilters();
  }

  onOrderValueChange(): void {
    this.list.refreshItems();
  }

  onFilterFormSubmit(): void {
    this.filterParams = {
      ...this.filters
    };
  }

  shouldModify(item: UserModel): boolean {
    return item.role !== UserRole.SUPER_ADMIN;
  }

  openUserModal(item: UserModel = null): void {
    this.$modals.show(UserModal, {
      item,
      onSubmit: () => {
        this.list.refreshItems();
      }
    });
  }

  openPatientHasDoctorsModal(item: UserModel = null): void {
    this.$modals.show(PatientHasDoctors, {
      patient: item,
      onSubmit: () => {
        this.list.refreshItems();
      }
    });
  }

  modifyStatus(item: UserModel, toActive: boolean): void {
    this.loading = true;
    this.$api.user.update(item.id, {status: toActive ? UserStatus.ACTIVE : UserStatus.INACTIVE})
      .then(() => this.list.refreshItems())
      .finally(() => this.loading = false)
  }

  removeItem(item: UserModel): void {
    this.$modals.show(ConfirmModal, {
      message: 'Biztosan törli a felhasználót?',
      onSubmit: () => {
        this.$api.user.delete(item.id)
          .then(() => this.list.refreshItems());
      }
    });
  }

  initFilters(): void {
    this.filters = {search: null, socialSecurityNumber: null, role: null};
  }

  clearFilters(): void {
    this.initFilters();
    this.onFilterFormSubmit();
  }

  isActive(user: UserModel): boolean {
    return user.status === UserStatus.ACTIVE;
  }

  authAsSuperAdmin(): boolean {
    return this.$store.getters.userInRole(UserRole.SUPER_ADMIN);
  }

  toUser(item: UserModel): UserModel {
    return item;
  }
}

