import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "nav-badge"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_icon = _resolveComponent("custom-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routes, (route, index) => {
    return (_openBlock(), _createElementBlock(_Fragment, null, [
      (route.show)
        ? (_openBlock(), _createElementBlock("li", {
            key: index,
            class: "nav-item"
          }, [
            _createVNode(_component_router_link, {
              to: {name: route.to},
              class: "nav-link",
              "active-class": "active",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emitRouteChange()))
            }, {
              default: _withCtx(() => [
                (route.icon)
                  ? (_openBlock(), _createBlock(_component_custom_icon, {
                      key: 0,
                      icon: route.icon
                    }, null, 8 /* PROPS */, ["icon"]))
                  : _createCommentVNode("v-if", true),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('page.' + route.label)), 1 /* TEXT */),
                (route.showBadge && route.showBadge(route.badgeValue()))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(route.badgeValue()), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])
          ]))
        : _createCommentVNode("v-if", true)
    ], 64 /* STABLE_FRAGMENT */))
  }), 256 /* UNKEYED_FRAGMENT */))
}