import { renderSlot as _renderSlot, withModifiers as _withModifiers, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["type", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.as === 'button')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (!_ctx.to)
            ? (_openBlock(), _createElementBlock("button", _mergeProps({
                key: 0,
                class: "button"
              }, _ctx.$attrs, {
                class: {
      [`button-${_ctx.color}`]: true,
      [`button--${_ctx.size}`]: true,
      'button--outlined': _ctx.outlined,
      'button--full-width': _ctx.fullWidth,
      'button--loading': _ctx.loading
    },
                type: _ctx.type,
                disabled: _ctx.disabled || _ctx.loading,
                onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.emitClick && _ctx.emitClick(...args)), ["stop","prevent"]))
              }), [
                _renderSlot(_ctx.$slots, "default")
              ], 16 /* FULL_PROPS */, _hoisted_1))
            : _createCommentVNode("v-if", true)
        ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
      : (_ctx.as === 'a')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (!_ctx.to)
              ? (_openBlock(), _createElementBlock("a", _mergeProps({
                  key: 0,
                  class: ["button", {
        [`button-${_ctx.color}`]: true,
        [`button--${_ctx.size}`]: true,
        'button--outlined': _ctx.outlined,
        'button--full-width': _ctx.fullWidth,
        'button--loading': _ctx.loading
      }]
                }, _ctx.$attrs, {
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.emitClick && _ctx.emitClick(...args)))
                }), [
                  _renderSlot(_ctx.$slots, "default")
                ], 16 /* FULL_PROPS */))
              : _createCommentVNode("v-if", true)
          ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
        : _createCommentVNode("v-if", true),
    (!!_ctx.to)
      ? (_openBlock(), _createBlock(_component_router_link, _mergeProps({
          key: 2,
          to: _ctx.to,
          tag: "button",
          class: ["button", {
      [`button-${_ctx.color}`]: true,
      [`button--${_ctx.size}`]: true,
      'button--outlined': _ctx.outlined,
      'button--full-width': _ctx.fullWidth
    }],
          disabled: _ctx.disabled ? true : null
        }, _ctx.$attrs, {
          onClick: _withModifiers(_ctx.emitClick, ["stop","prevent"])
        }), {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3 /* FORWARDED */
        }, 16 /* FULL_PROPS */, ["to", "class", "disabled", "onClick"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}