import Notification from '@/ui/components/Notification/Notification.vue'
import {createApp} from "vue";
import {debounce} from 'lodash';

function show(type: string, message: string, props = {}) {
  props['type'] = type
  props['message'] = message

  const tempDiv = document.createElement('div');
  const notifications = createApp(Notification, {
    ...props,
    destroy: () => {
      notifications.unmount();
      tempDiv.remove();
    }
  });

  return notifications.mount(tempDiv);
}

const showNotice = (message: string, props = {}) => show('notice', message, props);
const showSuccess = (message: string, props = {}) => show('success', message, props);
const showWarning = (message: string, props = {}) => show('warning', message, props);
const showError = (message: string, props = {}) => show('error', message, props);

const debouncedShowNotice = debounce(showNotice, 250);
const debouncedShowSuccess = debounce(showSuccess, 250);
const debouncedShowWarning = debounce(showWarning, 250);
const debouncedShowError = debounce(showError, 250);


class Notifications {
  app = null

  showNotice = (message: string, props = {}) => {
    debouncedShowNotice(message, props);
  }

  showSuccess = (message: string, props = {}) => {
    debouncedShowSuccess(message, props);
  }

  showWarning = (message: string, props = {}) => {
    debouncedShowWarning(message, props);
  }

  showError = (message: string, props = {}) => {
    props['delay'] = props['delay'] || 5000
    debouncedShowError(message, props);
  }
}

const notifications = new Notifications

export function useNotifications(): Notifications {
  return notifications;
}

export default {
  install: (app, options) => {
    notifications.app = app
    app.$notifications = notifications
    app.config.globalProperties.$notifications = notifications
  }
}

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $notifications: Notifications;
  }
}
