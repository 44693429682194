
import HeaderLayout from '@/ui/layouts/HeaderLayout.vue'
import AppMixin from '@/mixins/app.mixin';
import {mixins, Options} from 'vue-property-decorator';
import {AppActions} from '@/store/types';

@Options({
  name: 'MainLayout',
  components: {HeaderLayout}
})
export default class MainLayout extends mixins(AppMixin) {
  created() {
    this.$store.dispatch(AppActions.FETCH_MY_BOOKED_DATES)
  }
}
