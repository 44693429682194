import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_cookie_box = _resolveComponent("cookie-box")!
  const _component_ModalsContainer = _resolveComponent("ModalsContainer")!
  const _component_NotificationsContainer = _resolveComponent("NotificationsContainer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("<template v-if=\"isMobile\">"),
    _createCommentVNode("  <mobile-fallback />"),
    _createCommentVNode("</template>"),
    (_ctx.dataLoaded)
      ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
      : _createCommentVNode("v-if", true),
    (_ctx.showCookieBar)
      ? (_openBlock(), _createBlock(_component_cookie_box, {
          key: 1,
          onDestroy: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showCookieBar = false))
        }))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_ModalsContainer),
    _createVNode(_component_NotificationsContainer)
  ], 64 /* STABLE_FRAGMENT */))
}