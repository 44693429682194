
import {mixins, Options, Ref} from 'vue-property-decorator';
import AppMixin from '@/mixins/app.mixin';
import PageWrapper from '@/ui/components/PageWrapper.vue';
import {ListColumn} from '@/ui/components/List/list.types';
import List from '@/ui/components/List/List.vue';
import SelectInput from '@/ui/components/Form/Select/SelectInput.vue';
import TextInput from '@/ui/components/Form/TextInput.vue';
import ConfirmModal from '@/ui/modals/ConfirmModal.vue';
import DividedLayout from '@/ui/layouts/DividedLayout.vue';
import Button from '@/ui/components/Button.vue';
import OrderRuleModal from '@/ui/modals/OrderRuleModal.vue';
import {DoctorInterventionLimitModel} from '@/models/doctor-intervention-limit.model';
import {SelectOption} from '@/ui/components/Form/Select/select.types';
import {DoctorListItemModel} from '@/models/doctor-list-item.model';
import CustomIcon from '@/ui/components/CustomIcon.vue';
import OrderBySelect from '@/ui/components/Form/OrderBySelect.vue';
import {getOrderOptionValue} from '@/utils/form.util';
import {OrderByDirection} from '@/api/request/base.request';
import {OrderByPropertyNames} from '@/types';
import {AppActions} from '@/store/types';
import {ALL_DAYS_SELECT_VALUE} from '@/constants';

@Options({
  name: 'UsersPage',
  components: {OrderBySelect, CustomIcon, Button, DividedLayout, TextInput, SelectInput, List, PageWrapper}
})
export default class DoctorsPage extends mixins(AppMixin) {
  @Ref('list')
  list!: InstanceType<typeof List>;

  columns: ListColumn<DoctorInterventionLimitModel>[] = [
    {
      field: 'doctor_name',
      title: 'doctors.field_doctor'
    },
    {
      field: 'intervention_name',
      title: 'doctors.field_intervention'
    },
    {
      field: 'interval',
      title: 'doctors.field_interval'
    },
    {
      field: 'day_of_week',
      title: 'doctors.field_day_of_week'
    },
    {
      field: 'other',
      title: 'doctors.field_other'
    }
  ];

  doctorSelectOptions: SelectOption<DoctorListItemModel>[] = [];
  interventionSelectOptions: SelectOption[] = [];

  doctorsLoading = false;

  filters: Record<string, any> = {};
  filterParams: any = {};

  orderByProps: OrderByPropertyNames<DoctorInterventionLimitModel> = {
    from: 'Időpont'
  }
  orderValue: string = getOrderOptionValue('from', OrderByDirection.asc);
  readonly allDayValue = ALL_DAYS_SELECT_VALUE;

  get daySelectOptions(): SelectOption[] {
    return this.$store.state.dayOptions;
  }

  created() {
    this.initFilters();
    this.getDoctors();
    this.getDays();
  }

  onDoctorChange(): void {
    this.setInterventionSelectOptions();
  }

  onOrderValueChange(): void {
    if (this.list) {
      this.list.refreshItems()
    }
  }

  onFilterFormSubmit(): void {
    this.filterParams = {
      ...this.filters
    };
  }

  openOrderRuleModal(item: DoctorInterventionLimitModel = null, isCopy = false): void {
    this.$modals.show(OrderRuleModal, {
      item,
      isCopy,
      onSubmit: () => {
        this.list.refreshItems();
      }
    });
  }

  getDoctors() {
    this.doctorsLoading = true;
    return this.$api.dokirex.getAllDoctor()
      .then(r => {
        this.doctorSelectOptions = r.data.map(item => ({
          label: item.doctor_name,
          value: item.doctor_id,
          item
        }))
        this.setInterventionSelectOptions();
        return Promise.resolve(r);
      })
    .finally(() => this.doctorsLoading = false)
  }

  setInterventionSelectOptions(): void {
    const doctor = this.doctorSelectOptions.find(item => item.value === this.filters.doctor);

    this.interventionSelectOptions = doctor
      ? doctor.item.specialist_clinics.map(item => ({label: item.specialist_clinic_name, options: item.interventions.map(intervention => ({
          label: intervention.intervention_name,
          value: intervention.intervention_id
        }))}))
      : [];
  }

  removeItem(item: DoctorInterventionLimitModel): void {
    this.$modals.show(ConfirmModal, {
      message: 'Biztosan törli a rendelési megkötést?',
      onSubmit: () => {
        this.$api.limitation.delete(item.id)
          .then(() => this.list.refreshItems());
      }
    });
  }

  initFilters(): void {
    this.filters = {doctor: null, intervention: null};
  }

  resetFilters(): void {
    this.initFilters();
    this.onFilterFormSubmit();
  }

  toDoctorInterventionLimit(item): DoctorInterventionLimitModel {
    return item;
  }

  async getDays(): Promise<void> {
    await this.$store.dispatch(AppActions.GET_DAYS);
  }
}

