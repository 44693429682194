
import {Emit, mixins, Options, Prop} from 'vue-property-decorator';
import AppMixin from '@/mixins/app.mixin';
import {AppActions, AppState} from '@/store/types';
import Button from '@/ui/components/Button.vue';
import CustomIcon from '@/ui/components/CustomIcon.vue';

@Options({
  name: 'TimeSummary',
  components: {CustomIcon, Button}
})
export default class TimeSummary extends mixins(AppMixin) {
  @Prop({type: String, default: () => null})
  specialistClinicName!: string;

  @Prop({type: String, default: () => null})
  doctorName!: string;

  @Prop({type: String, default: () => null})
  interventionName!: string;

  @Prop({type: Boolean, default: () => true})
  hasSubmit!: boolean;

  @Prop({type: Boolean, default: () => true})
  showTime!: boolean;

  @Prop({
    type: String as () => 'vertical' | 'horizontal',
    default: () => 'vertical'
  })
  orientation!: 'vertical' | 'horizontal';

  @Emit('afterReservation')
  emitAfterReservation(): void {}

  loading = false;

  getSelections(): AppState['selections'] {
    return this.$store.state.selections;
  }

  getSelectedDateTime(): string {
    return this.moment(this.getSelections().time.date).format(`MMMM DD. ${this.getSelections().time.time.from}`)
  }

  async reservation(): Promise<void> {
    const {intervention, time, doctor} = this.getSelections();
    this.loading = true;

    try {
      await this.$api.dokirex.bookReservation({
        intervention_id: intervention.intervention_id,
        user_specialist_clinic_id: doctor.user_specialist_clinic_id,
        day_id: time.day_id,
        from: time.time.from,
        comment: 'Megjegyzés...',
        day: this.moment(time.date).format('YYYY-MM-DD')
      }).finally(() => this.loading = false)

      await this.$store.dispatch(AppActions.CLEAR_SELECTIONS);
      await this.$store.dispatch(AppActions.FETCH_MY_BOOKED_DATES);
    } finally {
      this.emitAfterReservation();
    }
  }
}
