

import {defineComponent, ref, onMounted, PropType} from 'vue'
import { Toast as BootstrapToast } from 'bootstrap'
import { removeElement } from '@/utils/dom.util'

export default defineComponent({
  name: 'Notification',
  components: {},
  props: {
    type: {
      type: String,
      default: 'success'
    },
    message: {
      type: String,
      required: true
    },
    delay: {
      type: Number,
      default: 3000
    },
    autoHide: {
      type: Boolean,
      default: true
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    onShow: {
      type: Function as PropType<() => void>,
      default:() => {}
    },
    onHide: {
      type: Function,
      default: () => {}
    },
    destroy: {
      type: Function,
      default: () => {}
    }
  },

  setup(props, context) {
    const notification = ref()
    let toast: BootstrapToast

    onMounted(() => {
      toast = new BootstrapToast(notification.value)
      const el = toast['_element'] as Element
      el.addEventListener('shown.bs.toast', function (event) {
        props.onShow()
      })

      el.addEventListener('hidden.bs.toast', function (event) {
        props.onHide()
        props.destroy();
      })

      toast.show()
    })

    const getClasses = (): { toast: string, close: string, icon?: string } => {
      switch (props.type) {
        case 'success':
          return { toast: 'text-light bg-success', close: 'btn-close-white', icon: 'bi bi-check-circle-fill' }
        case 'warning':
          return { toast: 'text-dark bg-warning', close: 'btn-close-default', icon: 'icon-warning' }
        case 'error':
          return { toast: 'text-light bg-danger', close: 'btn-close-white', icon: 'bi bi-exclamation-circle-fill' }
        default:
          return { toast: 'text-primary bg-default', close: 'btn-close-default', icon: 'icon-info' }
      }
    }

    const show = () => toast.show()
    const hide = () => toast.hide()
    const hasSlotContent = (slotName: string): boolean => !!context.slots[slotName]

    return {
      notification, hasSlotContent, getClasses, show, hide
    }
  }

})
