import {createI18n} from 'vue-i18n';
import locales from './locales'

declare const DEFAULT_LOCALE: string;

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: DEFAULT_LOCALE || 'hu',
  fallbackLocale: DEFAULT_LOCALE,
  messages: locales
})

export default i18n
