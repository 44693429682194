
import {mixins, Options, Ref, Watch} from 'vue-property-decorator';
import AppMixin from '@/mixins/app.mixin';
import {SelectOption} from '@/ui/components/Form/Select/select.types';
import {FreeTimeSegmentModel} from '@/models/free-time-segment.model';
import CollapseContainer from '@/ui/components/CollapseContainer.vue';
import CollapseList from '@/ui/components/CollapseList.vue';
import Calendar from '@/ui/components/Calendar/Calendar.vue';
import DividedLayout from '@/ui/layouts/DividedLayout.vue';
import {AppActions} from '@/store/types';
import {SpecialistClinicModel} from '@/models/specialist-clinic.model';
import {DoctorModel} from '@/models/doctor.model';
import {InterventionModel} from '@/models/intervention.model';
import TimeSummary from '@/ui/components/TimeSummary.vue';
import Button from '@/ui/components/Button.vue';
import CustomIcon from '@/ui/components/CustomIcon.vue';
import {priceInformationIsClose, setPriceInformationState} from '@/utils/site.util';

@Options({
  name: 'DashboardPage',
  components: {CustomIcon, Button, TimeSummary, DividedLayout, CollapseContainer, CollapseList, Calendar}
})
export default class DashboardPage extends mixins(AppMixin) {
  @Ref('calendar')
  calendar!: InstanceType<typeof Calendar>;

  @Ref('dividedLayout')
  readonly dividedLayout!: InstanceType<typeof DividedLayout>;

  @Watch('specialistClinic')
  onSpecialistClinicChange(): void {
    this.$store.dispatch(AppActions.SET_SPECIALIST_CLINIC, this.getSelectedSpecialistClinic());
    this.$store.dispatch(AppActions.SET_TIME, null);
  }

  @Watch('doctor')
  onDoctorChange(): void {
    this.$store.dispatch(AppActions.SET_DOCTOR, this.getSelectedSDoctor());
    this.$store.dispatch(AppActions.SET_TIME, null);
  }

  @Watch('intervention')
  onInterventionChange(): void {
    this.$store.dispatch(AppActions.SET_INTERVENTION, this.getSelectedSIntervention());
    this.$store.dispatch(AppActions.SET_TIME, null);
    this.getFreeTimeSegments();
    this.dividedLayout.showSidebar = false;
  }

  specialistClinicOptions: SelectOption[] = [];
  doctorSelectOptions: SelectOption[] = [];
  interventionSelectOptions: SelectOption[] = [];

  specialistsFetching = false;
  doctorsFetching = false;
  interventionsFetching = false;

  specialistClinic = null;
  doctor = null;
  intervention = null;
  freeTimeSegments: FreeTimeSegmentModel[] = [];
  calendarLoading = false;
  priceInformationIsOpen = !priceInformationIsClose();
  updatePriceInformationState = setPriceInformationState;
  timesFetched = false;

  created() {
    this.getSpecialistClinics();
  }

  beforeUnmount() {
    this.$store.dispatch(AppActions.CLEAR_SELECTIONS)
  }

  getSelectedSpecialistClinic(): SpecialistClinicModel | null {
    return this.specialistClinicOptions.find(
      option => option.value === this.specialistClinic
    )?.item ?? null
  }

  getSelectedSDoctor(): DoctorModel | null {
    return this.doctorSelectOptions.find(
      option => option.value === this.doctor
    )?.item ?? null
  }

  getSelectedSIntervention(): InterventionModel | null {
    return this.interventionSelectOptions.find(
      option => option.value === this.intervention
    )?.item ?? null
  }

  getSpecialistClinics(): void {
    this.specialistsFetching = true;
    this.$api.dokirex.getSpecialistClinics()
      .then(r => {
        this.specialistClinicOptions = r.data.map(item => ({
          label: item.specialist_clinic_name,
          value: item.specialist_clinic_id,
          item
        }))
      })
    .finally(() => this.specialistsFetching = false)
  }

  getDoctors(): void {
    if (!this.specialistClinic) {
      this.doctorSelectOptions = [];
      return;
    }

    this.doctorsFetching = true;
    this.$api.dokirex.getDoctors(this.specialistClinic)
      .then(r => {
        this.doctorSelectOptions = r.data.map(item => ({
          label: item.doctor_name,
          value: item.user_specialist_clinic_id,
          item
        }))
      })
      .finally(() => this.doctorsFetching = false)
  }

  getInterventions(): void {
    if (!this.doctor) {
      this.interventionSelectOptions = [];
      return;
    }

    this.interventionsFetching = true;
    this.$api.dokirex.getInterventions(this.doctor)
      .then(r => {
        this.interventionSelectOptions = r.data.map(item => ({label: item.intervention_name, value: item.intervention_id, item}))
      })
      .finally(() => this.interventionsFetching = false)
  }

  getFreeTimeSegments(): void {
    if (!this.doctor || !this.intervention) {
      return;
    }

    this.calendarLoading = true;
    this.$api.dokirex.getFreeTimeSegments({
      intervention_id: this.intervention,
      doctor_id: this.getSelectedSDoctor().doctor_id,
      specialist_clinic_id: this.getSelectedSpecialistClinic().specialist_clinic_id
    }).then(r => {
      this.freeTimeSegments = r.data;
      this.timesFetched = true;
    }).finally(() => this.calendarLoading = false)
  }
}
