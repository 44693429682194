
import ModalsContainer from '@/ui/components/Modal/ModalsContainer.vue'
import NotificationsContainer from '@/ui/components/Notification/NotificationsContainer.vue'
import {Options, Vue} from 'vue-property-decorator';
import {AppActions} from '@/store/types';
import CookieBox from '@/ui/components/CookieBox.vue';
import {couldShowCookie, isMobile} from '@/utils/site.util';
import MobileFallback from '@/ui/components/MobileFallback.vue';

@Options({
  name: 'App',
  components: {
    MobileFallback,
    CookieBox,
    ModalsContainer, NotificationsContainer
  }
})
export default class App extends Vue {
  dataLoaded = false;
  showCookieBar = couldShowCookie();
  isMobile = isMobile();

  created(): void {
    window.addEventListener('resize', this.onWindowResize);
    document.body.focus();
    this.loadData();
  }

  beforeUnmount(): void {
    window.removeEventListener('resize', this.onWindowResize);
  }

  async loadData() {
    await this.$store.dispatch(AppActions.GET_NATIONALITIES);
    this.dataLoaded = true;
  }

  private onWindowResize(): void {
    this.isMobile = isMobile();
  }
}
