import {AxiosInstance} from 'axios';

export abstract class AbstractApi {
  protected readonly axios: AxiosInstance;
  protected resource: string;

  constructor(readonly axiosInstance: AxiosInstance, resource?: string) {
    this.axios = axiosInstance;
    this.resource = resource;
  }
}
