import {Emit, mixins, Prop, Watch} from 'vue-property-decorator';
import AppMixin from '@/mixins/app.mixin';
import {Moment} from 'moment';
import {getCurrentYearMonthStr} from '@/ui/components/Calendar/utils';
import {Day} from '@/ui/components/Calendar/types';
import {FreeTimeSegmentModel} from '@/models/free-time-segment.model';

export class CalendarMixin extends mixins(AppMixin) {
  @Prop({type: Array, default: () => []})
  readonly times!: FreeTimeSegmentModel[];

  @Prop({type: Boolean, required: true})
  readonly timesFetched!: boolean;

  @Emit('headingChange')
  emitHeadingChange(yearStr: string, monthStr: string): void {}

  @Watch('times', {deep: true})
  onTimesChange(): void {
    this.setTimesAtDays();
  }

  days: Day[] = [];
  readonly weekDayNumber = 7;

  setCurrentYearMonth(
    periodStartMoment: Moment,
    periodEndMoment: Moment,
    showDays: boolean = false
  ): void {
    const result = getCurrentYearMonthStr(periodStartMoment, periodEndMoment, showDays);
    this.emitHeadingChange(result.yearStr, result.monthStr);
  }

  setTimesAtDays(): void {
    this.days.forEach(day => day.times = []);
    this.times.forEach(item => {
      const day = this.days.find(day => this.moment(day.date).isSame(item.day, 'day'));

      if (day) {
        day.times = item.free_intervals;
        day.day_id = item.day_id;
      }
    });
  }
}
