
import LocaleSelect from '@/ui/components/LocaleSelect.vue'
import RouteNavigation from '@/ui/components/RouteNavigation.vue'
import ROUTES from "@/router/routes";
import {mixins, Options, Watch} from "vue-property-decorator";
import AppMixin from '@/mixins/app.mixin';
import Button from '@/ui/components/Button.vue';
import CustomIcon from '@/ui/components/CustomIcon.vue';
import {getCustomerServiceData} from '@/utils/site.util';
import HamburgerButton from '@/ui/components/HamburgerButton.vue';

@Options({
  name: 'HeaderLayout',
  components: {
    HamburgerButton,
    CustomIcon,
    Button,
    RouteNavigation,
    LocaleSelect
  }
})
export default class HeaderLayout extends mixins(AppMixin) {
  @Watch('openedMobileMenu')
  onOpenedMobileMenuChange(): void {
    const htmlEl = document.querySelector('html');
    if (this.openedMobileMenu) {
      htmlEl.style.overflow = 'hidden';
    } else {
      htmlEl.style.removeProperty('overflow');
    }
  }

  routes = ROUTES;
  customerService = getCustomerServiceData();
  openedMobileMenu = false;
}
