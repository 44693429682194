import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("i", {
    class: _normalizeClass(["custom-icon", ['custom-icon-' + _ctx.icon]]),
    style: _normalizeStyle([_ctx.size ? `width: ${_ctx.size}px` : '']),
    innerHTML: _ctx.content
  }, null, 14 /* CLASS, STYLE, PROPS */, _hoisted_1))
}