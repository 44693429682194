
import {Emit, mixins, Options, Prop} from "vue-property-decorator";
import AppMixin from '@/mixins/app.mixin';
import {LocationAsPath, RouteLocation} from 'vue-router';

type ButtonType = 'button' | 'submit';
type ButtonColor = 'primary' | 'secondary' | 'maroon-flush' | 'bali-hai' | 'red-ribbon' | 'link' | 'wood-smoke';
type ButtonSize = 'sm' | 'md' | 'lg';
type ButtonAs = 'button' | 'a';

@Options({
  name: 'Button'
})
export default class Button extends mixins(AppMixin) {
  @Prop({
    type: String as () => ButtonType,
    default: () => 'button'
  })
  type!: ButtonType;

  @Prop({
    type: String as () => ButtonColor,
    default: () => 'primary'
  })
  color!: ButtonColor;

  @Prop({
    type: String as () => ButtonSize,
    default: () => 'md'
  })
  size!: ButtonSize;

  @Prop({
    type: Boolean,
    default: () => false
  })
  outlined!: boolean;

  @Prop({
    type: Boolean,
    default: () => false
  })
  fullWidth!: boolean;

  @Prop({
    type: Boolean,
    default: () => false
  })
  loading!: boolean;

  @Prop({
    type: Boolean,
    default: () => false
  })
  disabled!: boolean;

  @Prop({
    type: [String, Object],
    default: () => null
  })
  to!: string | RouteLocation;

  @Prop({
    type: String as () => ButtonAs,
    default: () => 'button'
  })
  as!: ButtonAs;

  @Emit('click')
  emitClick(event: MouseEvent) {}
}
