
import {Emit, mixins, Options, Prop, Watch} from 'vue-property-decorator';
import {CalendarMixin} from '@/mixins/calendar.mixin';
import { Day } from './types';
import {FreeTimeSegmentModel} from '@/models/free-time-segment.model';
import {Moment} from 'moment';
import {DateRange} from '@/types';
import {AppActions, AppState} from '@/store/types';

@Options({
  name: 'CalendarWeek'
})
export default class CalendarWeek extends mixins(CalendarMixin) {
  @Prop({type: Date, required: true})
  currentDate!: Date;

  @Prop({
    type: Object as () => DateRange,
    default: () => null
  })
  defaultSelectedDateTime!: DateRange;

  @Emit('periodChange')
  emitPeriodChange(from: Date, to: Date): void {}

  @Watch('currentDate', {immediate: true})
  onCurrentDateChange(): void {
    this.setCurrentYearMonth(this.startOfWeek, this.endOfWeek, true);
    this.setDays();
    this.setTimesAtDays();
    this.emitPeriodChange(this.startOfWeek.startOf('month').toDate(), this.startOfWeek.endOf('month').toDate());
  }

  selectedTime: {
    time: FreeTimeSegmentModel['free_intervals'][0],
    day_id: number
  } = null;

  get startOfWeek(): Moment {
    return this.moment(this.currentDate).clone().startOf('isoWeek');
  }

  get endOfWeek(): Moment {
    return this.moment(this.currentDate).clone().endOf('isoWeek');
  }

  created() {
    if (this.defaultSelectedDateTime) {
      const day = this.days.find(day => this.moment(day.date).isSame(this.defaultSelectedDateTime.from, 'day'));

      if (day) {
        this.selectTime(
          day,
          {
            from: this.moment(this.defaultSelectedDateTime?.from).format('HH:mm'),
            to: this.moment(this.defaultSelectedDateTime?.to).format('HH:mm')
          }
        )
      }
    }
  }

  isSelectedTime(day: Day, time: FreeTimeSegmentModel['free_intervals'][0]): boolean {
    if (!this.selectedTime?.day_id) {
      return false;
    }

    return this.selectedTime.day_id === day.day_id &&
      this.selectedTime.time.from === time.from;
  }

  selectTime(day: Day, time: FreeTimeSegmentModel['free_intervals'][0]): void {
    if (this.isSelectedTime(day, time)) {
      this.selectedTime = null;
      this.$store.dispatch(AppActions.SET_TIME, null);

      return;
    }

    this.selectedTime = {
      time,
      day_id: day.day_id
    };

    this.$store.dispatch(AppActions.SET_TIME, {
      date: day.date,
      day_id: day.day_id,
      time
    } as AppState['selections']['time']);
  }

  private getWeekDays(currentDate: Date = new Date()): Day[] {
    const weekStart = this.moment(currentDate).clone().startOf('isoWeek');
    const days: Day[] = [];

    for (let i = 0; i <= (this.weekDayNumber - 1); i++) {
      const day = this.moment(weekStart).add(i, 'days');
      const item: Day = {
        date: day.toDate(),
        title: day.format('DD'),
        subtitle: day.format('dddd')
      };

      days.push(item);
    }

    return days;
  }

  private setDays(): void {
    this.days = this.getWeekDays(this.currentDate);
  }
}
