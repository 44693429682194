

import {ListColumn} from './list.types'
import Pagination from "@/ui/components/List/Pagination.vue";
import {Emit, Options, Prop, Ref, Vue, Watch} from 'vue-property-decorator';
import {BaseRequest} from '@/api/request/base.request';
import BaseResponse from '@/api/response/base.response';
import {useI18n} from 'vue-i18n';
import {generateUUID} from '@/utils/site.util';
import {removeHtmlFromString} from '@/utils/dom.util';

type ListItem = ({ showDetails?: boolean, uuid?: string, id: number } & Record<string, any>);

@Options({
  name: 'List',
  components: {Pagination}
})
export default class List extends Vue {
  @Ref('selectAllCheckbox')
  selectAllCheckbox!: HTMLInputElement;

  @Prop({required: true, type: Array, default: () => []})
  columns!: ListColumn[];

  @Prop({type: Array, default: () => []})
  items!: ListItem[];

  @Prop({type: Object, default: () => ({last_page: 1, total: 0})})
  paginationData!: {
    last_page: number;
    total: number;
  };

  @Prop({type: Object, default: () => ({})})
  listParams!: Partial<BaseResponse & Record<string, any>>;

  @Prop({
    type: Function, default: () => () => {
    }
  })
  onPageChange!: (page: number) => void;

  @Prop({type: Boolean, default: () => false})
  loading!: boolean;

  @Prop({type: Boolean, default: () => false})
  selectable!: boolean;

  @Prop({type: Boolean, default: () => true})
  pagination!: boolean;

  @Prop({type: String, default: () => 'elem'})
  entityName!: string;

  @Prop({type: String, default: () => null})
  rowActionsHeader!: string;

  @Prop({type: Function})
  itemsGetter!: (params?: Partial<BaseRequest & Record<string, any>>) => Promise<BaseResponse<any[]>>;

  @Prop({type: Array, default: () => []})
  selectedItems!: ListItem[];

  @Watch('loading')
  onLoadingChange() {
    this.showLoader = this.loading;
  }

  @Watch('listParams', {deep: true})
  onListParamsChange(): void {
    this.currentPage = 1;
    this.refreshItems();
  }

  @Watch('listItems')
  onListItemsChange() {
    this.listItems.forEach(item => item.uuid = generateUUID());
  }

  @Emit('selectionChange')
  emitSelectionChange(items: any[]): void {
  }

  listItems: ListItem[] = [];
  computedSelectedItems: ListItem[] = [];
  currentPage: number = 1;
  showLoader: boolean = false;
  uuid = generateUUID();

  get fullWidthColspanValue(): number {
    let colspan = this.columns.length;

    colspan += [
      (this.hasSlotContent('details') || this.selectable),
      this.hasSlotContent('rowPrefix'),
      this.hasSlotContent('rowActions')
    ].filter(value => value).length;

    return colspan;
  }

  created() {
    this.listItems = this.items;
    this.paginationData.total = this.listItems.length;
    this.initSelectedItems();
    this.refreshItems();
  }

  mounted() {
    this.setCheckboxIndeterminateState()
  }

  onPaginationChange(page: number): void {
    this.currentPage = page;
    this.onPageChange(page);
    this.refreshItems();
  }

  getColumnTitle(column: ListColumn, removeHtml: boolean): string {
    const {te, t} = useI18n();
    let result = te(column.title) ? t(column.title) : column.title;

    if (removeHtml) {
      result = result.replaceAll(/\<br ?\/?\>/g, ' ');
      result = removeHtmlFromString(result);
    }

    return result;
  }

  getColumnValue(item: Object, column: ListColumn): string {
    return item.hasOwnProperty(column.field) ? item[column.field] ?? '--' : '--';
  }

  hasSlotContent(slotName: string): boolean {
    return !!this.$slots[slotName];
  }

  isSelected(item: ListItem): boolean {
    return this.computedSelectedItems.findIndex(_item => _item.uuid === item.uuid) >= 0;
  }

  isAllSelected(): boolean {
    if (!this.listItems.length) {
      return false;
    }

    return this.computedSelectedItems.length === this.listItems.length;
  }

  toggleSelection(item: ListItem): void {
    if (this.isSelected(item)) {
      const index = this.computedSelectedItems.findIndex(_item => _item.uuid === item.uuid);
      this.computedSelectedItems.splice(index, 1);
    } else {
      this.computedSelectedItems.push(item);
    }

    this.setCheckboxIndeterminateState();
    this.callEmitSelectionChange();
  }

  toggleAllSelection(forceState?: boolean): void {
    if (forceState !== undefined) {
      if (!forceState) {
        this.computedSelectedItems = [];
      } else {
        this.computedSelectedItems = [...this.listItems];
      }
    } else {
      if (this.isAllSelected()) {
        this.computedSelectedItems = [];
      } else {
        this.computedSelectedItems = [...this.listItems];
      }
    }

    this.callEmitSelectionChange();
  }

  refreshItems(callback: () => void = () => {
  }): void {
    if (this.itemsGetter && this.itemsGetter instanceof Function) {
      this.showLoader = true;
      this.itemsGetter({...this.listParams, page: this.currentPage, perPage: 10})
        .then(r => {
          this.paginationData.last_page = r?.meta?.last_page ?? 0;
          this.paginationData.total = r?.meta?.total ?? r.data?.length ?? 0;
          this.listItems = r.data || [];
          this.uuid = generateUUID();
          this.$nextTick(() => {
            this.$forceUpdate();
            this.initSelectedItems();
          });
          callback();
        })
        .finally(() => {
          this.showLoader = false;
          this.setCheckboxIndeterminateState();
        });
    }
  }

  private initSelectedItems(): void {
    this.computedSelectedItems = this.selectedItems.map(selectedItem => {
      const listItem = this.listItems.find(_item => _item.id === selectedItem.id);
      const uuid = listItem?.uuid ?? generateUUID();

      return {
        ...selectedItem,
        uuid
      };
    });
  }

  private callEmitSelectionChange(): void {
    this.emitSelectionChange(this.computedSelectedItems.map(_item => {
      const result = Object.assign({}, _item);
      delete result.uuid;
      delete result.showDetails;
      return result;
    }));
  }

  private setCheckboxIndeterminateState(): void {
    if (this.selectAllCheckbox) {
      this.selectAllCheckbox.indeterminate = !this.isAllSelected() &&
        !!this.computedSelectedItems.length;
    }
  }
}
