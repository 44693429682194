import {AxiosError, AxiosInstance} from 'axios';
import {saveFile} from '@/utils/file.util';
import BaseResponse from '@/api/response/base.response';
import {useNotifications} from '@/plugins/notifications';
import {blobToJson} from '@/utils/site.util';
import {FileResponse} from '@/api/response/file.response';

export function downloadFile(resource: string, axios: AxiosInstance, params: Record<string, any> = {}): Promise<boolean> {
  return axios.get(resource, {params})
    .then((response: any) => {
      const data = response as FileResponse;
      const base64 = `data:${data.mime};base64,${data.content}`;
      saveFile(
        base64,
        data.filename
      );
      return Promise.resolve(true);
    })
    .catch((e: AxiosError) => {
      const message = e?.response?.data?.message;
      if (message) {
        useNotifications().showError(message);
      }
      return Promise.reject(e) as any;
    })
}

export function showFallbackMessage(response: BaseResponse<any>, fallback: string): Promise<BaseResponse<any>> {
  if (!response?.message) {
    useNotifications().showSuccess(fallback);
  }

  return Promise.resolve(response);
}

export function getRequestData<T extends object, D extends object>(formData: D, item?: T, nullable = false, clearSameData = true): D {
  const result = Object.assign({}, formData);

  Object.keys(result)
    .forEach(key => {
      const value = result[key];

      if (!nullable) {
        if (value === null) {
          delete result[key];
        }
      }

      if (clearSameData) {
        if (typeof value == 'string' && item && item[key] === result[key]) {
          delete result[key];
        }
      }
    });

  return result;
}

export function clearEmptyString<T extends object>(data: T): T {
  Object.keys(data).forEach(key => {
    const value = data[key];
    if (value === '') {
      delete data[key];
    }
  })
  return data;
}
