
import AppMixin from '@/mixins/app.mixin';
import {mixins, Options, Ref} from 'vue-property-decorator';
import CenterBoxWrapper from '@/ui/components/CenterBoxWrapper.vue';
import ROUTES from '@/router/routes';
import Button from '@/ui/components/Button.vue';
import {ResetPasswordRequest} from '@/api/request/reset-password.request';
import PasswordInput from '@/ui/components/Form/PasswordInput.vue';
import {FormContext} from 'vee-validate';

@Options({
  name: 'PasswordResetPage',
  components: {PasswordInput, Button, CenterBoxWrapper}
})
export default class PasswordResetPage extends mixins(AppMixin) {
  @Ref('vForm')
  readonly vForm!: FormContext;

  formData: ResetPasswordRequest = {
    password_new: null,
    password_new_confirmation: null
  }
  loading = false;
  readonly routes = ROUTES;

  async onFormSubmit() {
    const validationResult = await this.vForm.validate();
    if (validationResult.valid) {
      try {
        this.loading = true;
        await this.$api.auth.csrf();
        await this.$api.user.resetPassword({
          ...this.formData,
          reset_code: this.$route.params.code as string ?? null
        }).finally(() => this.loading = false)
        await this.$router.push({name: this.routes.LOGIN});
      } catch (e) {
        this.loading = false;
      }
    }
  }
}
