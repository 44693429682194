import { mount } from '@/utils/dom.util'
import Modal from '@/ui/components/Modal/Modal.vue';

class Modals {
  app = null;
  private modalInstances: InstanceType<typeof Modal>[] = [];

  show = (component, props = {}) => {
    return mount(component, {
      props: props,
      children: null,
      element: null,
      app: this.app
    })
  }

  addInstance(instance: InstanceType<typeof Modal>): void {
    this.modalInstances.push(instance);
  }

  removeInstance(instance: InstanceType<typeof Modal>): void {
    const index = this.modalInstances.findIndex(item => item.uuid === instance.uuid);
    if (index > -1) {
      this.modalInstances.splice(index, 1);
    }
  }

  closeAll(): void {
    this.modalInstances.forEach(instance => {
      instance.close();
    });
  }
}

const modals = new Modals;

window['modals'] = modals;

export function useModals(): Modals {
  return modals;
}

export default {
  install: (app, options) => {
    modals.app = app
    app.$modals = modals
    app.config.globalProperties.$modals = modals
  }
}

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $modals: Modals;
  }
}
