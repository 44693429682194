
import {Options, Prop, Vue} from 'vue-property-decorator';

@Options({
  name: 'PageWrapper'
})
export default class PageWrapper extends Vue {
  @Prop({type: String, default: () => null})
  pageTitle!: string;

  @Prop({type: String, default: () => null})
  pageDescription!: string;
}
