
import {Emit, Model, Options, Prop, Vue, Watch} from "vue-property-decorator";

@Options({name: 'Pagination'})
export default class Pagination extends Vue {

  @Model('modelValue', {type: Number, default: () => 1})
  currentPage: number = 1;
  @Prop({required: true, type: Number})
  itemTotal: number;
  @Prop({type: Number, default: 10})
  perPage: number;
  @Prop({type: Number, default: 5})
  rangeLength: number;

  totalPage: number = 13;
  pageRange: Array<number> = [];
  showFirst: boolean = true;
  showLast: boolean = true;

  @Watch('itemTotal')
  onItemTotalChange(value: number) {
    this.setPageRange();
  }

  @Watch('perPage')
  onPerPageChange(value: number) {
    this.setPageRange();
  }

  @Emit('onPageChange')
  onPageChange(page: number) {
    this.currentPage = page;
    this.setPageRange();
  }

  mounted() {
    this.setPageRange();
  }

  selectPage(page: number) {
    if (this.currentPage !== page) {
      this.onPageChange(page);
    }
  }

  selectNextPage() {
    this.selectPage(this.currentPage + 1)
  }

  selectPrevPage() {
    this.selectPage(this.currentPage - 1)
  }

  setPageRange() {
    this.totalPage = Math.ceil(this.itemTotal / this.perPage);
    let sideRange = Math.floor(this.rangeLength / 2);
    let rangeFrom = this.getRangeFrom(sideRange);
    this.showFirst = rangeFrom > 1;
    this.showLast = (this.totalPage > this.rangeLength) && this.currentPage + sideRange + 1 < this.totalPage;
    this.pageRange = Array.from({length: this.rangeLength >> 0}, (_, i) => i + rangeFrom).filter(page => page > 0);
  }

  isFirstPage() {
    return this.currentPage === 1
  }

  getRangeFrom(sideRange: number): number {
    let rangeFrom = (this.currentPage > sideRange) ? this.currentPage - sideRange : 1;
    if ((rangeFrom + this.rangeLength) > this.totalPage) {
      rangeFrom = (this.totalPage - this.rangeLength) + 1;
    }
    return rangeFrom;
  }

  isLastPage() {
    return this.currentPage === this.totalPage;
  }

  isCurrentPage(i: number) {
    return this.currentPage === i;
  }
}
