export enum UserRole {
  PATIENT = 'patient',
  ADMIN = 'admin',
  SUPER_ADMIN = 'superAdmin',
}

export enum UserUsableRoles {
  PATIENT = 'patient',
  ADMIN = 'admin',
}

export enum UserStatus {
  PENDING = 'pending',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum UserSex {
  MALE = 1,
  FEMALE = 2
}
