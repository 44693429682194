
import AppMixin from '@/mixins/app.mixin';
import {mixins, Options, Ref} from 'vue-property-decorator';
import CenterBoxWrapper from '@/ui/components/CenterBoxWrapper.vue';
import ROUTES from '@/router/routes';
import Button from '@/ui/components/Button.vue';
import TextInput from '@/ui/components/Form/TextInput.vue';
import {FormContext} from 'vee-validate';

@Options({
  name: 'PasswordResetRequestPage',
  components: {TextInput, Button, CenterBoxWrapper}
})
export default class PasswordResetRequestPage extends mixins(AppMixin) {
  @Ref('vForm')
  readonly vForm!: FormContext;

  routes = ROUTES;
  email: string = null;
  loading = false;

  async onFormSubmit() {
    const validationResult = await this.vForm.validate();
    if (validationResult.valid) {
      try {
        this.loading = true;
        await this.$api.auth.csrf();
        await this.$api.user.resetPassword({email: this.email}).finally(() => this.loading = false)
        await this.$router.push({name: this.routes.LOGIN});
      } catch (e) {
        this.loading = false;
      }
    }
  }
}
