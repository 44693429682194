import { render, h, VNode } from 'vue'
import {ScriptInjection} from "@/types";

export const createElement = (tag: string = 'div'): Element | boolean => {
  return typeof document !== 'undefined' && document.createElement(tag)
}

export const removeElement = (el): void => {
  if (typeof el.remove !== 'undefined') {
    el.remove()
  } else {
    el.parentNode.removeChild(el)
  }
}

export const mount = (component, { props, children, element, app }) => {
  let el: Element | null = element ? element : createElement()
  let vNode: VNode | null = h(component, props, children)

  if (vNode && app && app._context) {
    vNode.appContext = app._context
  }

  if (el) {
    render(vNode, el)
  }

  const destroy = () => {
    if (el) {
      render(null, el)
    }
    el = null
    vNode = null
  }

  return { vNode, destroy, el }
}

export function removeHtmlFromString(value: string): string {
  const tempEl = document.createElement('div');
  tempEl.innerHTML = value;
  const result = tempEl.textContent || tempEl.innerText || '';
  tempEl.remove();
  return result;
}

export function createScriptInjection(htmlStr: string, className: string): ScriptInjection {
  const $container = document.head;
  const range = document.createRange();

  $container.appendChild(
    range.createContextualFragment(
      htmlStr.replaceAll('<script', `<script class="${className}"`)
    )
  )

  return {
    remove() {
      $container.querySelectorAll(`.${className}`).forEach(el => el.remove())
    }
  }
}
