import moment from 'moment';

moment.locale('hu_HU');

const appMoment = window['moment'] = moment;
export {appMoment};

export default {
  install(app) {
    app.config.globalProperties.moment = appMoment;
  }
};

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    moment: typeof moment;
  }
}
