
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import ROUTES from '@/router/routes';

export default defineComponent({
  name: 'NotFoundPage',

  props: {
    path: String,
  },

  setup() {
    const router = useRouter()
    const back = () => router.replace(`/${ROUTES.DASHBOARD}`)

    return {
      back
    }
  }

})
