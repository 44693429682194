import {createApp} from 'vue'
import App from './App.vue'
import router from '@/router'
import i18n from '@/i18n'
import store from '@/store';
import api from '@/api'
import '@/assets/style/app.scss'
import modals from '@/plugins/modals'
import notifications from '@/plugins/notifications'
import {ErrorMessage, Field} from "vee-validate";
import 'bootstrap-icons/font/bootstrap-icons.css';
import {TooltipDirective} from '@/plugins/tooltips';
import appMoment from '@/utils/moment.util';
import '@/validator';
import {DatePicker} from 'v-calendar';
import CKEditor from '@ckeditor/ckeditor5-vue';
import Maska from 'maska';
import AppForm from '@/ui/components/Form/AppForm.vue';

const app = createApp(App)
  .use(router)
  .use(i18n)
  .use(store)
  .use(api)
  .use(modals)
  .use(notifications)
  .use(appMoment)
  .use(CKEditor)
  .use(Maska);

app.provide(AppForm.name, null);

app.component('Form', AppForm);
app.component('Field', Field);
app.component('VeeErrorMessage', ErrorMessage);
app.component('DatePicker', DatePicker);

app.directive('tooltip', TooltipDirective);

app.mount('#app');
