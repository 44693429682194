
import {Emit, mixins, Model, Options, Prop} from 'vue-property-decorator';
import AppMixin from '@/mixins/app.mixin';
import moment from 'moment';
import {DateRange} from '@/types';
import {FreeTimeSegmentModel} from '@/models/free-time-segment.model';
import {CalendarView} from '@/ui/components/Calendar/types';
import CalendarMonth from '@/ui/components/Calendar/CalendarMonth.vue';
import CalendarWeek from '@/ui/components/Calendar/CalendarWeek.vue';
import {MONTHS_LIMIT} from '@/ui/components/Calendar/constants';

@Options({
  name: 'Calendar',
  components: {CalendarWeek, CalendarMonth}
})
export default class Calendar extends mixins(AppMixin) {
  @Model('modelValue', {type: Object, default: () => ({from: null, to: null})})
  weekRange!: DateRange;

  @Prop({type: Array, default: () => []})
  times!: FreeTimeSegmentModel[];

  @Prop({type: Boolean, default: () => false})
  loading!: boolean;

  @Prop({type: Boolean, required: true})
  readonly timesFetched!: boolean;

  @Emit('periodChange')
  emitPeriodChange(from: Date, to: Date): void {}

  yearStr: string = null;
  monthStr: string = null;

  actualDate = new Date();
  view: CalendarView = 'month';
  defaultSelectedDateTime: DateRange = null;
  readonly viewDurationMap: { [k in CalendarView]: moment.unitOfTime.DurationConstructor } = {
    month: 'months',
    week: 'weeks'
  };

  onHeadingChange(yearStr: string, monthStr: string): void {
    this.yearStr = yearStr;
    this.monthStr = monthStr;
  }

  openWeek(date: Date): void {
    this.actualDate = date;
    this.view = 'week';
  }

  goToEarliestTime(): void {
    if (!this.times.length) {
      return;
    }

    let earliestRange: DateRange = null;

    this.times.forEach(date => {
      date.free_intervals.forEach(time => {
        const dateTimeFrom = `${this.moment(date.day).format('YYYY-MM-DD')} ${time.from}`;
        const dateTimeTo = `${this.moment(date.day).format('YYYY-MM-DD')} ${time.to ?? ''}`.trim();

        if (!earliestRange?.from && this.moment(dateTimeFrom).isAfter(new Date(), 'minute')) {
          earliestRange = {from: this.moment(dateTimeFrom).toDate(), to: this.moment(dateTimeTo).toDate()};
        } else if (earliestRange?.from && this.moment(dateTimeFrom).isBefore(earliestRange.from) && this.moment(dateTimeFrom).isAfter(new Date(), 'minute')) {
          earliestRange = {from: this.moment(dateTimeFrom).toDate(), to: this.moment(dateTimeTo).toDate()};
        }
      })
    })

    if (!earliestRange?.from) {
      this.$notifications.showWarning('Nem található foglalható időpont!')
    } else {
      this.defaultSelectedDateTime = earliestRange;
      this.openWeek(earliestRange.from);
    }
  }

  isWeekView(): boolean {
    return this.view === 'week';
  }

  isMonthView(): boolean {
    return this.view === 'month';
  }

  prevPeriod(): void {
    if (this.prevIsDisabled()) {
      return;
    }

    this.actualDate = this.getPrevDate();
  }

  nextPeriod(): void {
    if (this.nextIsDisabled()) {
      return;
    }

    this.actualDate = this.getNextDate();
  }

  prevIsDisabled(): boolean {
    return this.moment(this.getPrevDate())
      .endOf(this.view)
      .isSameOrBefore(new Date(), 'day');
  }

  nextIsDisabled(): boolean {
    const limitDate = this.moment(new Date()).add(MONTHS_LIMIT, 'months').endOf('months').toDate();
    return this.moment(this.getNextDate())
      .startOf(this.view)
      .isAfter(limitDate, 'day');
  }

  getPrevDate(): Date {
    return this.moment(this.actualDate)
      .subtract(1, this.viewDurationMap[this.view])
      .toDate()
  }

  getNextDate(): Date {
    return this.moment(this.actualDate)
      .add(1, this.viewDurationMap[this.view])
      .toDate();
  }
}
